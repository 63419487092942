import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, interval, take, takeWhile } from 'rxjs';

export enum MDropdownPosition {
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  bottomCenter,
}

@UntilDestroy()
@Component({
  selector: 'ui-m-dropdown, [uiMDropdown]',
  templateUrl: './m-dropdown.component.html',
  styleUrls: ['./m-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*' })),
      state('collapsed', style({ height: '0px' })),
      transition('expanded <=> collapsed', animate(200)),
    ]),
  ],
})
export class MobileDropdownComponent implements AfterViewInit {
  @ViewChild('triggerContainer') triggerContainer: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef<HTMLDivElement>;
  positions = MDropdownPosition;

  get position() {
    return this._position;
  }
  @Input() set position(value: MDropdownPosition) {
    this._position = value;

    if (this.dropdownContainer?.nativeElement) {
      this.updatePosition();
    }
  }

  showDropdown = false;

  private _position = this.positions.bottomRight;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      interval(0)
        .pipe(
          untilDestroyed(this),
          takeWhile(() => !this.dropdownContainer?.nativeElement),
          finalize(() => {
            this.updatePosition();
            this._ngZone.run(() => {});
          }),
        )
        .subscribe(() => {});
    });
  }

  updatePosition() {
    switch (this.position) {
      case this.positions.topLeft:
      case this.positions.topRight:
      case this.positions.bottomLeft:
        this.dropdownContainer.nativeElement.style.right = '0';
        this.dropdownContainer.nativeElement.style.top = this.triggerContainer.nativeElement.offsetHeight + 1 + 'px';
        this.dropdownContainer.nativeElement.style.left = 'unset';
        break;
      case this.positions.bottomCenter:
        this.dropdownContainer.nativeElement.style.right =
          'calc(' + this.triggerContainer.nativeElement.getBoundingClientRect().right + 'px - 100vw)';
        this.dropdownContainer.nativeElement.style.width =
          'calc(100vw - max(12px, env(safe-area-inset-right)) - max(12px, env(safe-area-inset-left)))';
        this.dropdownContainer.nativeElement.style.marginRight = 'max(12px, env(safe-area-inset-right))';
        break;
      default:
        this.dropdownContainer.nativeElement.style.right = 'unset';
        this.dropdownContainer.nativeElement.style.top = this.triggerContainer.nativeElement.offsetHeight + 1 + 'px';
        this.dropdownContainer.nativeElement.style.left = '0';
    }
    this._cdRef.markForCheck();
  }

  toggle(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.showDropdown = !this.showDropdown;
    this._cdRef.markForCheck();
  }
}
