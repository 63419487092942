import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-account-panel-subscription-management',
  templateUrl: './m-account-panel-subscription-management.component.html',
  styleUrls: ['./m-account-panel-subscription-management.component.scss'],
})
export class MobileAccountPanelSubscriptionManagementComponent implements OnInit {
  constructor(private _accountPanelService: AccountPanelService) {}

  ngOnInit(): void {}

  onClose() {
    this._accountPanelService.closePanel();
  }
}
