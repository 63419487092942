<div class="frame">
  <section class="header">
    <div class="icon-wrapper back" (click)="onBack()">
      <div class="icon icon-back xs"></div>
    </div>
    {{ 'accountPanel.details.securitySettings.withdrawal.title' | translate }}
    <div class="icon-wrapper close" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="body">
    <main>
      <section class="logo-wrapper">
        <div class="icon-wrapper">
          <div class="icon icon-ownxr"></div>
        </div>
      </section>
      <section class="support-message">
        {{ 'accountPanel.details.securitySettings.withdrawal.supportMessage' | translate: { username: account.Username } }}
      </section>
      <section class="privacy-info card">
        <div class="title">{{ 'accountPanel.details.securitySettings.withdrawal.pleaseCheck' | translate | uppercase }}</div>
        <div class="separator"><div></div></div>
        <div class="content">
          @if ((currentLanguage$ | async) === 'ko') {
            제13조 (개인정보보호) ① 회사는 이용자의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다. ②
            회사는 회원가입 시 결제이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에
            본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다. ③ 회사는 이용자의 개인정보를
            수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. ④ 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수
            없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고
            동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다. ⑤ 회사는 제2항과 제3항에 의해 이용자의 동의를 받아야
            하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보
            제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조 제2항이
            규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다. ⑥ 이용자는 언제든지 회사는 가지고
            있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 할 의무를 집니다.
            이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. ⑦ 회사는 개인정보
            보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행 계좌 등을 포함한 이용자의 개인정보의
            분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다. ⑧ 회사 또는 그로부터
            개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. ⑨
            회사는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한
            이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의
            거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
          } @else {
            ① The company collects the minimum amount of personal information necessary for providing services when collecting user's
            personal information. ② The company does not collect information required for payment execution during user registration.
            However, in cases where personal verification is necessary before entering into a purchase agreement due to legal obligations,
            the company may collect a minimum amount of specific personal information. ③ When collecting and using the user's personal
            information, the company notifies the user of the purpose and obtains consent. ④ The company shall not use the collected
            personal information for purposes other than the original intent, and when a new purpose arises or when providing to a third
            party, the company shall notify the user of the purpose and obtain consent during the use and sharing process. However,
            exceptions may apply in accordance with relevant laws. ⑤ When the user's consent is required under paragraphs 2 and 3, the
            company must specify in advance or notify the user of the matters set forth in Article 22, Paragraph 2 of the "Act on Promotion
            of Information and Communication Network Utilization and Information Protection," including the identity of the personal
            information manager (affiliation, name, phone number, and other contact information), the purpose of collecting and using
            information, and the details of providing information to third parties (recipient, purpose of provision, and contents of
            information). The user has the right to withdraw this consent at any time. ⑥ The user may request to access and correct their
            personal information held by the company at any time, and the company must promptly take necessary measures. If the user
            requests correction of an error, the company shall not use the relevant personal information until the error is corrected. ⑦ The
            company shall minimize the number of personnel handling the user's personal information for the purpose of protecting personal
            information and shall be fully responsible for any damages caused to the user due to the loss, theft, leakage, unauthorized
            provision to a third party, tampering, etc., of the user's personal information, including credit cards and bank accounts. ⑧ The
            company or a third party that has received personal information shall promptly dispose of the relevant personal information when
            the purpose of collection or receipt has been achieved. ⑨ The company does not pre-select the consent boxes for the collection,
            use, and provision of personal information. Furthermore, the company does not restrict or refuse membership registration or
            service provision based on the user's refusal to consent to the collection, use, and provision of non-essential personal
            information, and specific services that are limited by such refusal should be clearly specified.
          }
        </div>
      </section>
      <section class="reason card">
        <div class="title">{{ 'accountPanel.details.securitySettings.withdrawal.whyLeaving' | translate | uppercase }}</div>
        <div class="separator"><div></div></div>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          placeholder="{{ 'shared.fields.selectPlaceholder' | translate }}"
          dropdownPosition="top"
          [(ngModel)]="reason"
          (click)="$event.stopImmediatePropagation()"
        >
          @for (option of reasonOptions; track option) {
            <ng-option [value]="option">
              <div class="select-option-wrapper">
                {{ 'accountPanel.details.securitySettings.withdrawal.options.' + option | translate }}
              </div>
            </ng-option>
          }
        </ng-select>
      </section>
    </main>
  </section>
  <section class="footer">
    <button ui-button (click)="sendFeedback()">
      {{ 'accountPanel.details.securitySettings.withdrawal.sendFeedback' | translate }}
    </button>
    <button ui-outlined-button (click)="deleteAccount()">
      {{ 'accountPanel.details.securitySettings.withdrawal.leave' | translate }}
    </button>
  </section>
</div>
