<div uiMWrapper (uiClickOutside)="showDropdown = false">
  <div #triggerContainer class="trigger-container" (click)="toggle($event)">
    <ng-content select="[trigger]"></ng-content>
  </div>

  <div #dropdownContainer [@expandCollapse]="showDropdown ? 'expanded' : 'collapsed'" class="dropdown-container">
    <div class="padding-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>
