<div class="frame">
  <section class="header">
    {{ 'accountPanel.footer.termsTitle' | translate }}
    <div class="icon-wrapper" (click)="closeDetails()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="body">
    @if ((currentLanguage$ | async) === 'ko') {
      <div class="container">
        <div class="block"></div>
        <div class="contents">
          <h2 class="priority-title">제 1장 총칙</h2>
          <h2 class="sub-title first">제1조 (목적)</h2>
          <p class="summary">
            본 약관은 주식회사 유룸(이하 "회사")이 운영하는 웹 기반 메타버스 제작 플랫폼 “OwnXR”을 통해 제공하는 제반 서비스(이하 “OwnXR
            서비스”, 또는 “서비스”)를 이용자(이하 "회원")가 이용할 수 있도록 "회사"가 "회원"에게 이용 권한을 부여함에 있어서 필요한 제반
            사항 및 상호 간의 권리와 의무, 책임 사항을 규정함을 그 목적으로 합니다.
          </p>
          <h2 class="sub-title">제2조 (용어의 정의)</h2>
          <p>2.1. 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.</p>
          <p>
            -“사이트”라 함은 “OwnXR” 서비스 제공을 위하여 “회사”가 운영하는 웹사이트(www.ownxr.com과 추후 추가되는 웹사이트 포함)를
            의미합니다.
          </p>
          <p>- “회원“이라 함은 본 약관에 동의하고 회원 가입을 완료한 “서비스” 이용자를 말하며, 개인 회원과 기업 회원이 있습니다.</p>
          <p>
            - “회원명”(ID)이라 함은 “회원”의 식별과 “서비스” 이용을 위하여 “회원”이 정하고 “회사”가 승인하는 이메일 또는 문자와 숫자,
            특수기호의 조합을 의미합니다.
          </p>
          <p>
            - “비밀번호”(PASSWORD)라 함은 “회원”이 “서비스”를 이용하는 과정에서 “회원명”을 사용할 권한이 있는 “회원”임을 확인하고, “회원”의
            “서비스” 내 비밀을 보호하기 위하여 “회원” 자신이 정한 문자 또는 숫자, 특수기호의 조합을 의미합니다.
          </p>
          <p>- “계정”이라 함은 “회원명”과 “비밀번호”에 기반한 로그인 계정을 의미합니다.</p>
          <p>
            - “유료서비스계약”이라 함은 회사가 회원에게 정해진 수량, 범위, 권한, 환불 정책으로 서비스(이를 “유료서비스”라고 합니다)를
            제공하고, 회원이 회사에 일정 금액을 결제하는, 회사와 회원 간에 체결되는 별도의 계약을 의미합니다.
          </p>
          <p>
            - “해지”라 함은 “회사” 혹은 “회원”이 “유료서비스계약을 종료시키는 것으로 OwnXR 사이트 내의 유료서비스 이용의 중단을 의미하며,
            “탈퇴“는 “회원“이 직접 사이트의 가입을 철회하는 회원 탈퇴를 의미합니다.
          </p>
          <p>- “회원에셋”이라 함은 회원이 “OwnXR”을“OwnXR”을 사용하여 제작한 메타버스 콘텐츠를 의미합니다.</p>
          <p>
            - “회원공간”이라 함은 무료 구독 혹은 구독결제(유료서비스계약)을(를) 통해 생성되는 '회원'이 편집 및 공유를 할 수 있는 3차원의
            공간 자산입니다. “회원에셋”과 “회원공간”을 합하여, “회원에셋등”이라고 합니다.
          </p>
          <p>
            - “회원링크”라 함은 “OwnXR”을 통해 회원이 제작 혹은 구성한 “회원공간”을 제3자와 공유할 수 있는 회원이 소유하거나 관리하는
            웹사이트 또는 링크를 의미합니다.
          </p>
          <p>
            2.2. 본 약관에서 사용하는 용어의 정의는 전 항에서 정하는 것을 제외하고는 개인정보보호법 기타 관계 법령 및 “OwnXR” 이용안내, 기타
            상관례에 따릅니다.
          </p>
          <h2 class="sub-title">제3조 (약관의 효력 및 변경)</h2>
          <p>3.1. 본 약관은 “OwnXR”을“OwnXR”을 이용하는 모든 “회원”에 대하여 그 효력이 발생합니다.</p>
          <p>
            3.2. 본 약관은 "OwnXR" 이용을 위해 "회사"가 제공한 "사이트"에 접속하여 이용자 계정을 생성하거나 "OwnXR" 내 기타 기능을
            이용함으로써 "회원"은 본 약관에 대해 동의를 한 것으로 간주합니다.
          </p>
          <p>
            3.3. "회원"이 본 약관에 동의하지 않을 경우 "OwnXR" 이용자 계정을 생성하거나 "OwnXR" 사이트에서 제공하는 기타 기능을 이용할 수
            없습니다.
          </p>
          <p>
            3.4. “회사”는 필요하다고 인정되는 경우 본 약관을 임의로 변경할 수 있으며, “회사”가 약관을 변경할 경우에는 적용일자 및 변경
            사유를 명시하여, 제11조의 규정과 같은 방법으로 그 적용일자 15일 전부터 공지합니다. 다만, “회원”에게 불리한 약관의 변경인
            경우에는 그 적용 일자 30일 전부터 공지하며, 서비스 페이지 내 제공 화면 및 이메일 또는 SMS 중 회사가 선택한 방법으로 “회원”에게
            개별 통지합니다. (“회원”의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 경우에 한하여 “사이트”에 변경 약관을
            공지함으로써 개별 통지한 것으로 간주합니다.)
          </p>
          <p>
            3.5. “회사”가 본 조 제4항에 따라 변경 약관을 공지 또는 통지하면서, “회원”이 약관 변경 적용일 까지 거부의사를 표시하지 아니할
            경우 또는 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 “회원”이 명시적으로 변경된 약관의 효력
            발생일까지 약관 변경에 대한 거부의사를 표시하지 아니하면 “회원”이 변경 약관에 동의한 것으로 간주합니다. “회원”은 변경된 약관에
            동의하지 않을 경우 “OwnXR”의 이용을 중단하고 “이용 계약”을 해지할 수 있습니다.
          </p>
          <p>
            3.6. 본 약관에 동의하는 것은 “사이트”를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에
            대한 정보를 알지 못하여 발생하는 “회원”의 피해에 대하여 “회사”는 책임을 지지 않습니다.
          </p>
          <h2 class="sub-title">제4조 (약관 외 준칙)</h2>
          <p>
            4.1. 본 약관에 명시되지 않은 사항에 대해서는 개인정보보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의 관계법령에
            의합니다.
          </p>
          <p>
            4.2. "회원"이 "OwnXR"과 상품, 서비스, 기타 내용에 대해 “유료서비스계약” 등 별도의 계약이나 별도의 이용약관(이하 "개별계약")을
            체결했을 경우, "회원"은 본 약관 외에 "개별계약"의 규정에 따라 “OwnXR”을 이용해야 합니다.
          </p>
          <h2 class="priority-title">제2장 서비스 이용계약</h2>
          <h2 class="sub-title first">제5조 (가입)</h2>
          <p>
            5.1. “서비스”를 이용하려고 하는 자(이하 “가입신청자”)는 “서비스”에 가입하여야 하고, “가입신청자”가 약관의 내용에 동의한다는
            표시로 체크박스에 체크를 함으로써 가입신청을 하고 “회사”가 이러한 신청에 대해 승낙함으로써 가입이 성립됩니다. 이 경우
            “가입신청자”는 아래의 사항에 동의한 것으로 간주됩니다.
          </p>
          <p>- 본 이용약관에 동의하며</p>
          <p>- 본 이용약관을 위반할 경우 제재조치가 취해질 수 있음을 인지하고 이에 대해 동의합니다.</p>
          <p>5.3. “회사”는 필요하다고 인정하는 경우 “가입신청자”에게 가입을 위한 별도의 서류를 제출하도록 할 수 있습니다.</p>
          <p>
            5.4. “회사”는 다음 각 호에 해당하는 경우에는 가입의 승낙을 유보하거나 하지 않을 수 있고, 사후에 이용계약을 해지할 수도 있습니다.
          </p>
          <p>- 본 약관에 의해 이전에 “회사”가 계약을 해지했던 “회원”이 다시 가입 신청을 하는 경우</p>
          <p>- 본 약관에 의해 탈퇴했던 “회원”이 30일 이내에 동일한 이메일 주소로 다시 가입을 신청한 경우</p>
          <p>- 본인의 실명으로 신청하지 않은 경우</p>
          <p>- 다른 사람의 명의를 사용하여 신청한 경우</p>
          <p>- 가입신청서에 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은 경우</p>
          <p>- "서비스”의 모방·도용 및 “서비스”의 구체적 정보 취득 등을 목적으로 가입 신청을 하는 경우</p>
          <p>- “가입신청자” 혹은 “회원”의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반사항을 위반하여 신청하는 경우</p>
          <p>- “서비스” 사이트를 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우</p>
          <p>- 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 ”회사”의 서비스 제공에 악영향을 줄 수 있다고 판단되는 경우</p>
          <p>
            - 기타 “회사”가 관련법령 등을 기준으로 하여 명백하게 타 “회원”에게 해가 되거나 사회질서 및 미풍양속에 반할 우려가 있음을
            인정하는 경우
          </p>
          <p>
            5.5. “회사”는 서비스 관련 설비의 여유가 없거나, 서비스를 제공하는 데 있어 기술상 또는 업무상 문제가 있는 경우, 기타 “회사”가
            재정적, 기술적으로 필요하다고 판단하는 경우에는 가입 승낙을 유보할 수 있습니다.
          </p>
          <p>5.6. 제4항에 따라 가입 승낙이 유보되는 경우, “회사”는 “가입신청자”가 입력한 이메일 주소로 이를 알릴 수 있습니다.</p>
          <p>5.7. 이용계약의 성립 시기는 “회사”가 신청절차 상에서 별도의 이메일 또는 SMS를 발송하여 가입완료를 표시한 시점으로 합니다.</p>
          <p>5.8. “회사”는 회사정책에 따라 “회원”에게 등급을 부여할 수 있고, 서비스 이용에 있어 등급에 따른 차등을 둘 수 있습니다.</p>
          <h2 class="sub-title">제6조 (회원 정보의 변경)</h2>
          <p>
            6.1. “회원”은 설정 및 마이페이지 메뉴 등을 통해 언제든지 본인의 회원정보를 열람할 수 있으며, 해당 페이지에서의 입력, 혹은
            “회사”에 대한 요청을 통해 정보를 수정할 수 있습니다.
          </p>
          <p>
            6.2. “회원”은 가입신청시 기재한 사항을 변경할 경우 “회사”에 그 변경사항을 알려야 하며, 변경사항을 “회사”에 알리지 않아 발생한
            불이익에 대해서 “회사”는 책임을 부담하지 않습니다.
          </p>
          <p>
            6.3. “회원”은 연락수단(이메일, 휴대전화번호) 등 가입신청시 기재한 사항이 변경될 경우 이를 지체 없이 “회사”에 알리거나 개인정보
            변경을 통해 변경 등록을 해야 하며, 회원 정보를 변경하지 않아 발생한 손해에 대하여 “회사”는 책임을 부담하지 않습니다.
          </p>
          <h2 class="sub-title">제7조 (개인정보의 수집 및 보호)</h2>
          <p>7.1. “회사”는 서비스 제공을 위하여 “회원”으로부터 필요한 개인정보를 수집합니다.</p>
          <p>
            7.2. 제1항에 따라 수집된 개인정보에 대하여, “회사”는 “회원”의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에
            대해서는 관련법 및 회사의 개인정보 처리방침이 적용됩니다.
          </p>
          <p>7.3. 다만, “회사”의 공식 서비스 이외의 링크된 사이트에서는 회사의 개인정보 처리방침이 적용되지 않습니다.</p>
          <h2 class="sub-title">제8조 (회원의 회원명 등의 관리에 대한 책임) </h2>
          <p>
            8.1. “회원”의 “회원명(ID)”과 “비밀번호(PASSWORD)”의 관리에 대한 모든 책임은 “회원”에게 있으며, “회원”의 고의 혹은 관리소홀에
            따른 과실로 인하여 이를 제3자가 이용할 경우 이에 대한 모든 불이익에 대한 책임은 회원에게 있습니다.
          </p>
          <p>
            8.2. “회원”은 “회원명(ID)”, “비밀번호(PASSWORD)” 및 추가정보 등을 도난당하거나 제3자가 사용하고 있음을 인지한 경우 이를 즉시
            “회사”에 통보하여 “회사”의 안내에 따라야 합니다.
          </p>
          <p>
            8.3. 제2항의 경우에 “회원”이 “회사”에 그 사실을 통지하지 않거나 통지한 경우에도 “회사”의 안내에 따르지 않아 발생한 불이익에
            대하여 “회사”는 책임을 부담하지 않습니다.
          </p>
          <p>
            8.4. “회사”는 “회원”의 “회원명”이 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나, “회사” 및 “OwnXR”의 관리자로
            오인될 우려가 있는 경우, 해당 회원명의 이용을 제한할 수 있습니다.
          </p>
          <p>8.5 “회원”은 “회원명(ID)”을 “회사”의 사전 동의 없이 변경할 수 없습니다.</p>
          <h2 class="sub-title">제9조 (서비스 이용) </h2>
          <p>9.1. 회사가 회원에게 제공하는 “서비스”는 다음과 같습니다.</p>
          <p>(1) 웹 기반의 “OwnXR”을 통한 ”회원”의 ”회원에셋등” 제작, 편집, 관리 솔루션 서비스</p>
          <p>(2) “OwnXR” 사이트 내 또는 “회원링크”를 통한 다른 회원과 소통 및 “회원에셋등” 공유 및 활용 지원</p>
          <p>(3) “OwnXR” 사이트와 내 “회원에셋등” 데이터의 저장과 관리</p>
          <p>(4) 회원에셋등”의 [사용허락,] 취득, 구매, 관리 및 관련 설정 기능</p>
          <p>(5) “회원에셋등” 제작과 편집, 업로드에 필요한 애플리케이션 제작 및 제공 또는 판매</p>
          <p>(6) 기타 회사가 개발하거나 다른 회사와 협력계약을 통해 회사가 이용자에게 제공하는 서비스</p>
          <p>
            9.2. “서비스” 이용은 “회사”의 가입 신청 승낙 직후부터 가능합니다. 단, 본인인증을 완료하지 않은 경우 서비스의 일부 또는 전부의
            사용에 제한이 있을 수 있습니다.
          </p>
          <p>9.3 회사는 회원에게 다음의 조건으로 유료서비스를 제공합니다.</p>
          <p>
            - 유료서비스는 개인 회원에게 제공되는 “OwnXR”의 모든 기능을 사용할 수 있는 유료 구독 서비스(Pro Plan), 기업 회원에게 제공되는
            기업 맞춤형 서비스(Enterprise Plan)와 제작 서비스(Customization)가 있습니다.
          </p>
          <p>- 유료서비스는 회원이 결제를 완료한 경우, 유료서비스계약의 조건에 따라 제공됩니다.</p>
          <p>- 유료서비스의 이용요금은 신용카드사 또는 페이팔, Stripe 등 결제대행사를 통해 결제됩니다.</p>
          <p>
            - 유료서비스의 가격은 회사의 영업상 경영상의 합리적 판단과 결정에 따라 결정되며, 서비스의 내용이 유사한 경우에도 회원마다 가격이
            다를 수 있으며, 동일한 가격인 경우에도 회원마다 서비스의 내용에 차이가 있을 수 있습니다. 회사는 운영상, 기술상의 필요에 따라
            공급하는 유료서비스 가액을 변경할 수 있으며, 이 경우 “회원”에게 사전공지 합니다.
          </p>
          <p>
            - 유료서비스에 대한 환불 또는 유료서비스계약의 해지에 관해서는 회원이 체결한 유료서비스계약의 규정이 본 약관에 우선하여
            적용됩니다.
          </p>
          <p>9.4무상 서비스는 있는 그대로(AS IS) 제공하며, 회사는 이에 대해 어떠한 종류의 보증도 제공하지 않습니다.</p>
          <p>
            9.5 “회사”는 홍보와 판촉을 위해 회사의 판단과 결정에 따라 회원의 전부 또는 일부에게 무상으로 서비스를 제공할 수 있으며, 무상
            서비스의 제공을 일방적으로 종료할 수 있습니다.
          </p>
          <p>9.6. “회원”의 서비스 이용 권리는 이를 양도 내지 증여하거나 질권의 목적으로 사용할 수 없습니다.</p>
          <p>
            9.7. “회원”의 서비스 이용이 정상적인 절차에 따라 이루어지지 않고 있다고 판단될 경우, 회사가 개입하여 “회원”의 행위를 대신할 수
            있습니다. 이 때 판단의 권한은 전적으로 “회사”에 있습니다.
          </p>
          <h2 class="sub-title">제10조 (서비스 이용시간)</h2>
          <p>
            10.1. 서비스 이용시간은 “회사”가 기술상, 혹은 업무상으로 서비스 제공이 불가능한 경우를 제외하고는 연중무휴 1일
            24시간(00:00-24:00)으로 함을 원칙으로 합니다.
          </p>
          <p>
            10.2. “회사”는 서비스 설비의 정기점검, 교체 및 고장, 통신두절 등 운영상의 상당한 이유가 있는 경우 서비스의 제공을 일시적으로
            중단할 수 있습니다. 만일 서비스 제공의 중단이 24시간을 초과할 경우 회사는 제11조에 따라 “회원”에게 사전 통지하며, 사전에 통지할
            수 없는 부득이한 사유가 있는 경우에는 사후에 통지합니다.
          </p>
          <p>
            10.3. “회사”는 서비스 제공에 필요하다고 판단되는 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 페이지 내 제공 화면을
            통하여 공지합니다.
          </p>
          <p>10.4. 서비스 내용 중 온라인 문의는 답변하는 관리자의 개인사정에 따라 1일 24시간 서비스가 불가능 할 수 있습니다.</p>
          <h2 class="sub-title">제11조 (회원에 대한 통지) </h2>
          <p>
            11.1. “회사”가 “회원”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스에 등록된 “회원”의 이메일로 할 수 있습니다.
          </p>
          <p>
            11.2. 제1항의 경우에 “회원”이 이메일 인증을 하지 않거나 고의 혹은 과실로 허위의 이메일을 입력하여 통지를 받지 못하는 경우 회사는
            책임을 부담하지 않습니다.
          </p>
          <p>
            11.3. “회사”는 회원 전체에 대한 통지의 경우 서비스 페이지 내 제공 화면 또는 별도의 공지 게시를 통해 7일 이상 게시함으로써
            제1항의 통지에 갈음할 수 있습니다.
          </p>
          <h2 class="sub-title">제12조 (서비스의 변경, 중단 및 제한) </h2>
          <p>
            12.1. “회사”는 “OwnXR 서비스”의 내용, 품질, 또는 기술적 사양 등에 대해 “회사”의 합리적 판단에 따라 변경할 수 있습니다. 이 경우
            변경되는 “OwnXR 서비스” 이용 내용 및 제공 일자를 명시하여 제공 일자로부터 15일 전에 미리 “사이트” 초기화면 내 일부화면 또는
            이메일을 통해 공지합니다. 단, 변경 내용이 “회원”에게 불리한 변경의 경우에는 적용일자 30일 이전부터 적용일자 전일까지 공지합니다.
          </p>
          <p>12.2. “회사”는 다음 각 호에 해당하는 경우 “OwnXR 서비스” 이용의 전부 또는 일부를 제한하거나 중단, 종료할 수 있습니다.</p>
          <p>- ”OwnXR 서비스” 이용 설비의 보수점검, 교체, 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우</p>
          <p>- “회원”이 “회사”의 영업활동을 방해하는 경우</p>
          <p>- 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 “OwnXR 서비스” 이용에 지장이 있는 경우</p>
          <p>- ”회사”의 제반 사정으로 “OwnXR 서비스”를 유지할 수 없는 경우</p>
          <p>- 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</p>
          <p>12.3. “회원”은 다음 각 호에서 정한 바에 따라 “이용계약”을 해지할 수 있습니다.</p>
          <p>
            - ”회원”은 언제든지 “회사”에 서비스 이용계약 해지 의사를 통지할 수 있으며 서비스 이용계약을 해지하고자 할 경우 본인이 “OwnXR
            서비스” 사이트에서 이용 중단에 대한 해지를 신청하거나 회원 탈퇴를 진행할 수 있습니다.
          </p>
          <p>
            - 서비스 “이용계약” 해지 및 ”회원” 탈퇴로 인해 발생한 불이익에 대한 책임은 “회원” 본인이 져야 하며, “이용계약”이 종료되면
            “회사”는 “회원”에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
          </p>
          <p>- “회원”은 "OwnXR 서비스" 이용 관련 내용에 동의하지 않을 경우 “이용 계약”의 해지를 요청할 수 있습니다.</p>
          <p>12.4. “회사”는 "회원"의 “OwnXR 서비스” 이용의 변경, 중단, 종료로 인하여 발생하는 문제에 대해서 어떠한 책임도 지지 않습니다.</p>
          <p>
            12.5. “회사”는 “회원”이 제14조에 규정한 “회원”의 의무를 이행하지 않을 경우 사전 통지 없이 즉시 “이용계약”을 해지하거나 또는 해당
            “회원”의 “OwnXR 서비스”를 제한할 수 있습니다.
          </p>
          <p>12.6. 본 조 제5항의 “회사” 조치에 대하여 “회원”은 “회사”가 정한 절차에 따라 이의신청을 할 수 있습니다.</p>
          <p>12.7. 본 조 제6항의 이의가 정당하다고 “회사”가 인정하는 경우, “회사”는 즉시 “OwnXR 서비스” 제공을 재개합니다.</p>
          <p>
            12.8. “회사”는 “회원”의 사망 등의 사유로 인하여 “서비스”의 이용이 불가능한 것으로 판단되는 경우 직권으로 이용계약을 해지할 수
            있습니다.
          </p>
          <p>
            12.9. “회사”는 1년 이상 장기간 휴면상태인 “회원”의 경우 직권으로 이용계약 해지에 관한 사항을 서비스 사이트에 공지하거나 이메일로
            안내하고 서비스 사용을 제한하거나 이용계약을 해지할 수 있습니다.
          </p>
          <h2 class="priority-title">제3장 계약 당사자의 의무 </h2>
          <h2 class="sub-title first">제13조 (회사의 의무) </h2>
          <p>
            13.1. “회사”는 관계 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 약관에서 정한 바에 따라 계속적∙안정적으로
            서비스를 제공하기 위해 노력할 의무가 있습니다
          </p>
          <p>
            13.2. “회사”는 “OwnXR 서비스”의 제공과 관련하여 수집한 “회원”의 개인정보를 본인의 사전 동의 없이 제3자에게 누설, 배포하지
            않습니다. 단, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구받은 경우나 방송통신심의위원회의 요청이 있는 경우 등 법률의
            규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.
          </p>
          <p>
            13.3. “회사”는 “회원”이 안정적으로 “서비스”를 이용할 수 있도록 “회원”의 개인정보(신용정보포함)보호를 위한 시스템을 갖추어야
            합니다.
          </p>
          <p>
            13.4. “OwnXR 서비스”와 관련한 “회원”의 불만사항이 고객센터를 통하여 접수되는 경우 “회사”는 이를 적절한 절차를 거쳐 처리 하며,
            처리 시 일정기간이 소요될 경우 그 사유와 처리 일정을 SMS, 이메일 등을 통하여 동 “회원”에게 통지합니다.
          </p>
          <p>13.5. “회사”는 정보통신망법, 통신비밀보호법, 등 “OwnXR 서비스”의 운영, 유지와 관련한 법규를 준수합니다.</p>
          <h2 class="sub-title">제14조의1 (회원의 의무) </h2>
          <p>14.1. “회원”은 다음 각 호의 어느 하나에 해당하는 행위를 해서는 안 됩니다.</p>
          <p>(1) 가입신청 또는 “회원” 정보 변경 시 허위내용을 기재하는 행위</p>
          <p>(2) 타인의 개인정보를 도용하거나 자신의 개인정보를 제3자에게 이용하게 하는 행위</p>
          <p>
            (3) “회사”의 “서비스”에 게시된 정보를 변경하거나 “서비스”를 이용하여 얻은 정보를 “회사”의 사전 승낙 없이 영리 또는 비영리의
            목적으로 복사, 가공, 번역, 2차적 저작 등을 통하여 복제, 공연, 방송, 전시, 배포, 출판 등에 사용하거나 제3자에게 제공하는 행위
          </p>
          <p>
            (4) “회사”가 제공하는 “서비스”를 이용하여 제3자에게 “회원” 본인을 홍보할 기회를 제공하거나 제3자의 홍보를 대행하는 등의 방법으로
            금전을 수수하거나 “서비스”를 이용할 권리를 양도하고 이를 대가로 금전을 수수하는 행위
          </p>
          <p>(5) 기타 제3자에 대한 허위의 사실을 게재하거나 지식재산권을 침해하는 등 “회사”나 제3자의 권리를 침해하는 행위</p>
          <p>(6) 다른 “회원”의 “회원명(ID)” 및 “비밀번호(PASSWORD)”를 도용하여 부당하게 “서비스”를 이용하는 행위</p>
          <p>(7) 타인의 계좌번호 및 신용카드번호 등 타인의 허락 없이 타인의 결제정보를 이용하여 회사의 유료서비스를 이용하는 행위</p>
          <p>
            (8) 피라미드 조직이나 테러 조직 등에 가입할 것을 권유하는 내용을 게시하거나 외설 또는 폭력적인 메시지 • 화상 • 음성 등을
            게시하거나 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
          </p>
          <p>(9) 정보통신망법 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)를 전송하거나 게시하는 행위</p>
          <p>(10) 청소년보호법에서 규정하는 청소년유해매체물을 게시하는 행위</p>
          <p>(11) 공공질서 또는 미풍양속에 위배되는 내용의 정보 • 문장 • 도형 • 음성 등을 유포하는 행위</p>
          <p>
            (12) “회사”의 직원이나 “서비스”의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 모욕하는 글을 게시하거나 메시지를 발송하는
            행위
          </p>
          <p>
            (13) 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른
            컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 발송하는 행위
          </p>
          <p>(14) 스토킹(stalking), 욕설, 글 도배 등 다른 “회원”의 서비스 이용을 방해하는 행위</p>
          <p>(15) 다른 “회원”의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위</p>
          <p>
            (16) 불특정 다수를 대상으로 하여 광고 또는 선전을 게시하거나 스팸 메일을 전송할 목적으로 다른 “회원”들의 정보를 수집하는 행위
          </p>
          <p>(17) “회사”가 제공하는 소프트웨어 등을 개작하거나 리버스 엔지니어링, 디컴파일링, 디스어셈블 하는 행위</p>
          <p>(18) “회사”의 “서비스” 설명이나 약관 등 “회사”의 지식재산을 “회사”의 동의 없이 무단으로 도용하는 행위</p>
          <p>(19) “서비스” 내의 정보를 “회사”의 경쟁사, 혹은 잠재적 경쟁사에 제공하는 행위</p>
          <p>(20) “회사”의 서버 등 “회원”들에게 개방되지 않은 부분의 정보에 접근하는 행위</p>
          <p>
            (21) 개인 정보를 노출하거나 개인 연락처를 노출하는 방법으로 “회사”와 사전에 동의되지 않은 “서비스” 외의 개별 거래를 시도하는
            행위
          </p>
          <p>(22) 현행 법령, 회사가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위</p>
          <p>(23) 기타 다른 회원의 서비스 사용을 방해하거나 “회사”의 서비스 제공을 방해하는 행위</p>
          <p>(24) 기타 “회사” 및 서비스에 위해가 될 수 있는 행위</p>
          <p>
            14.2. 회사는 회원이 제14.1조에 저촉되는 행위를 하는 경우 해당 게시물 등을 삭제 또는 임시삭제 할 수 있고, 서비스 이용 제한, 계약
            해지, 영구 탈퇴, 민형사상의 고소/고발 등의 조치를 취할 수 있으며, 이로 인하여 회사에 손해가 발생한 경우 손해배상을 청구할 수
            있습니다.
          </p>
          <h2 class="sub-title">제14조의2 (회원 간의 회원에셋등의 거래) </h2>
          <p>14-2.1 판매자의 의무</p>
          <p>
            회원이 “회원에셋등”을 다른 회원에게 판매(사용허락)하고자 하는 경우(판매하는 회원을 “판매자”라고 하고, 구매하는 회원을 “구매자”라
            함), “회사”는 “사이트”를 통해 이 거래를 중개합니다.
          </p>
          <p>“판매자”는 다음 사항을 준수하여야 합니다.</p>
          <p>
            (1) “판매자”는 '회원에셋등'을 직접 에디터에 등록하고 “사이트”가 개설한 마켓플레이스에 업로드해 판매해야 합니다. 이 때,
            “회원에셋등”의 가격은 마켓플레이스 수수료 등을 고려하여 “판매자”가 스스로 결정하고, '회사'는 이에 관여하지 아니합니다. 다만,
            “회사”는 “구매자”의 피해방지를 위하여 결제 방식 등 관련 사항을 별도로 정할 수 있습니다.”
          </p>
          <p>(2) “판매자가 판매한 '회원에셋등'에 문제가 있을 경우 해당 '회원에셋등'을 업데이트할 의무가 있습니다.</p>
          <p>
            (3) “판매자”는 전가상거래 등에서의 소비자보호에 관한 법률에 의해 '회원에셋등'의 판매와 관련해 법령이 요구하는 사항을 지켜야
            합니다.
          </p>
          <p>
            (4) “판매자”는 '사이트'를 통하지 않고 구매자에게 직접 상품을 판매하거나 이를 유도해서는 안되며, 이를 위반하면 '회사'는 해당
            '판매자'의 마켓플레이스 서비스 이용을 정지하거나 이용계약을 해지할 수 있습니다. '판매자'는 판매되는 “회원에셋둥”에 대한 보증
            서비스를 자신의 책임과 비용으로 시행하여야 합니다.
          </p>
          <p>
            (5) “판매자”는 “구매자”의 문의에 성실하고 정확히 응대할 의무가 있습니다. “판매자”의 불성실, 부정확한 답변으로 “구매자”에게
            손해가 발생할 경우 “판매자”가 이에 대해 책임을 부담합니다.
          </p>
          <p>
            14-2.2 “판매자”가 마켓플레이스에서 이미 판매한 "회원에셋등"의 경우 이를 마켓플레이스에서 삭제하더라도 해당 “회원에셋등”을 이미
            구매한 “구매자”는 삭제 이후에도 이를 자유롭게 사용할 수 있습니다.
          </p>
          <p>14-2.3 “회원에셋등”에 대한 환불이 발생할 경우 “회사”에서 “구매자”에게 환불금을 선지급하고, “판매자”에게 추후 요청합니다.</p>
          <p>
            14-2.4 “회사”는 “회원에셋등”에 대한 거래의 중개자로, “구매자”와 “판매자” 간의 분쟁이 있을 경우 '회사'는 중재에 최선을 다하지만,
            “구매자”와 “판매자”에 대해 어떠한 책임도 부담하지 않습니다.
          </p>
          <p>14-2.5 “판매자”에 대한 정산은 Stripe을 통해 진행되며, '회사'가 선택한 통화로 송금합니다.</p>
          <p>
            (1) 정산은 매월 1일부터 말일까지를 산정기간으로 하여 익월 10일에 진행됩니다. 정산은 정산을 신청한 “판매자”를 대상으로만
            진행되며, 정산 가능한 금액이 100USD 이상일 때에만 신청 가능합니다.
          </p>
          <p>(2) 회원 탈퇴 시 해당 금액은 정산되지 않으며 권리를 포기하는 것으로 간주합니다.</p>
          <h2 class="priority-title">제4장. 일반 조항 </h2>
          <h2 class="sub-title first">제15조 (권리의 귀속) </h2>
          <p>15.1. “서비스”에 대한 저작권 등 기타 지식재산권은 “회사”에 귀속됩니다.</p>
          <p>
            15.2. “회사”는 “서비스”와 관련하여 “회원”에게 “회사”가 정한 이용조건에 따라 계정, “회원명”, 콘텐츠 등을 이용할 수 있는
            이용권만을 부여하며, 회원은 이를 양도, 판매, 재판매, 담보제공 등의 처분행위를 할 수 없습니다.
          </p>
          <h2 class="sub-title">제16조 (회원에셋등과 게시물의 저작권)  </h2>
          <p>
            16.1 회원이 제작한 “회원에셋등”과 회원이 “서비스” 사이트에 게재한 모든 자료 (“회원” 간 전달 및 “회원”의 회원링크 공유 포함, 이하
            “게시물”이라 함)에 대한 권리는 게시한 “회원”에게 있으며 “회사”는 게시자의 동의 없이는 영리적 목적으로 사용할 수 없습니다. 단,
            “회원에셋등” 제작에 사용된 “회사” 또는 제3자가 제공한 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지 및 영상 등(이들의 복합체를
            포함한다)의 자료 또는 정보 등의 콘텐츠는 제외합니다.
          </p>
          <p>
            16.2회원이 제작 또는 게시한 회원에셋등과 게시물이 타인의 저작권을 침해하여 발생하는 모든 책임 또한 회원 본인에게 귀속됩니다.
            만일 회원이 타인의 저작권 등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사를
            면책하여야 합니다.
          </p>
          <h2 class="sub-title">제17조 (환불) </h2>
          <p>
            17.1. “회원”이 과오 납입한 경우, “회사”는 해당하는 금액을 환불합니다. 이 경우 환불 절차로 인하여 발생하는 수수료는 “회원”이
            부담합니다.
          </p>
          <p>17.2. 변심 등 “회원”의 귀책사유로 환불하는 경우, 환불 절차로 인하여 발생하는 수수료는 “회원”이 부담합니다.</p>
          <p>17.3. 제2항의 규정에도 불구하고 아래 각 호의 경우에는 “회원”이 결제한 전액을 환불합니다.</p>
          <p>
            - “회원”이 결제를 완료한 후 24시간 이내에 “서비스”를 이용한 내역이 없는 경우 - “서비스” 장애 등 “회사”의 귀책사유로 “서비스”를
            이용할 수 없는 상황인 경우
          </p>
          <p>
            - 제1호의 사유로 환불하는 경우, 발생하는 수수료는 “회원”이 부담합니다. 제2호의 사유로 환불하는 경우에는 발생하는 수수료를
            “회사”가 부담합니다.
          </p>
          <p>
            17.4. “회원”이 제14조의 규정을 위반하여 “회사”가 “회원”의 “서비스” 이용을 제한하거나 일방적으로 본 계약을 해지하는 경우 “회사”는
            “회원”으로 인하여 발생한 손해액에 상응하는 금액에 대해 환불하지 않습니다.
          </p>
          <p>
            17.5. “회원”은 이용계약을 해지하거나 “서비스”를 탈퇴하는 경우 사전에 환불 요청을 해야 하며, 환불 요청 없이 이용계약을 해지하거나
            “서비스”를 탈퇴한 경우 “회사”는 환불의 의무를 지지 않습니다.
          </p>
          <p>
            17.6. “회원”이 이용한 결제수단을 이용하여 환불하는 것을 원칙으로 하나, 이것이 불가능한 경우에는 “회사”가 정하는 별도의 방법으로
            환불합니다.
          </p>
          <p>
            17.7. “회사”는 환불 절차를 7 영업일 이내에 처리하는 것을 원칙으로 하며, 신용카드사, 페이팔, Stripe 등 제3자의 처리 지연 및
            “회원”의 귀책사유로 인한 지연이 발생한 경우 해당 날짜는 처리 기간에 산입하지 않습니다.
          </p>
          <h2 class="sub-title">제18조 (정보의 제공 및 광고의 게재) </h2>
          <p>
            18.1. ”회사”는 “OwnXR 서비스”를 운영함에 있어 각종 정보를 “사이트”에 게재하거나 이메일 및 SMS 혹은 서신 우편 등의 방법으로
            “회원”에게 제공할 수 있습니다.
          </p>
          <p>18.2. ”회사”는 “회원”의 동의 하에 이메일, SMS 등을 통하여 개발에 유용한 정보와 기타 광고 등을 송신할 수 있습니다.</p>
          <h2 class="sub-title">제19조 (손해배상) </h2>
          <p>
            19.1. “회사”는 “회원”이 “OwnXR 서비스”를 이용함에 있어 “회사”의 고의로 인해 손해가 발생한 경우에는 민법 등 관련 법령이 규율 하는
            범위 내에서 그 손해를 배상합니다.
          </p>
          <p>
            19.2. “회원”은 본 약관을 위반하거나 관계 법령을 위반하여 “회사”에 손해가 발생한 경우에는 “회사”에 그 손해를 배상하여야 합니다.
          </p>
          <p>
            19.3. “회원”이 본 약관을 위반하거나 관계 법령을 위반하여 제3자가 “회사”를 상대로 민•형사상의 법적 조치를 취하는 경우에는
            “회원”은 자신의 비용과 책임으로 “회사”를 면책시켜야 하며, 이로 인해 발생하는 모든 손해에 대해 배상하여야 합니다.
          </p>
          <h2 class="sub-title">제20조 (면책사항) </h2>
          <p>
            20.1. “회사”는 “OwnXR 서비스”와 관련하여 “회사”의 고의 또는 중과실이 없이 “회원”에게 발생한 일체의 손해에 관하여 책임을 부담하지
            않습니다.
          </p>
          <p>
            20.2. “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “OwnXR 서비스”를 제공할 수 없는 경우에는 “OwnXR 서비스”에 관한
            책임이 면제됩니다.
          </p>
          <p>20.3. “회사”는 “회원”의 귀책사유로 인한 “OwnXR 서비스”의 이용 장애에 대하여 책임을 지지 않습니다.</p>
          <p>
            20.4. “회사”는 “회원”이 “OwnXR 서비스”의 이용으로 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 “OwnXR 서비스”를
            통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.
          </p>
          <p>20.5. “회사”는 “회원”이 “사이트”에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등 내용에 대한 책임을 지지 않습니다.</p>
          <p>20.6 “회원”의 고의 혹은 과실로 인하여 발생하는 “회원에셋등“와 관련된 손해 또는 책임소재 발생에는 책임을 지지 않습니다.</p>
          <p>
            20.7. “회사”는 “회원” 상호간 또는 “회원”과 제3자 상호간에 “OwnXR 서비스”를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며
            이로 인한 손해를 배상할 책임이 없습니다.
          </p>
          <p>
            20.8. “회원”이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 “회사”는 일절 책임을 지지 않습니다.
          </p>
          <p></p>
          <p></p>
          <h2 class="sub-title">제21조 (양도 및 권한 위임 금지)</h2>
          <p>
            “회원”은 “회사”의 동의 없이 본 약관 상의 권리 또는 의무의 전부 또는 일부를 제3자에게 양도, 위임하거나 담보의 목적으로 제공할 수
            없습니다.
          </p>
          <h2 class="sub-title">제22조 (준거법 및 관할법원)</h2>
          <p>22.1. “회사”와 “회원” 간 제기된 소송은 대한민국 법을 준거법으로 합니다.</p>
          <p>
            22.2. “OwnXR” 서비스 이용과 관련하여 “회사”와 “회원” 사이에 분쟁이 발생한 경우, 쌍방 간에 분쟁의 해결을 위해 성실한 협의가
            선행되어야 하며, 그렇지 아니하고서는 제소할 수 없습니다.
          </p>
          <p>
            22.3 제1항의 협의에서도 분쟁이 해결되지 않아 “회사”와 “회원”간 제기된 소송의 경우 대한민국 법을 준거법으로 하며 “회사”의
            소재지를 관할하는 법원을 전속 관할법원으로 합니다.
          </p>
          <p class="apply-date">이 이용약관은 2023년 10월 13일부터 적용됩니다. </p>
        </div>
      </div>
    } @else {
      <div class="container">
        <div class="block"></div>
        <div class="contents">
          <h2 class="priority-title">GENERAL TERMS</h2>
          <h2 class="sub-title first">1. Purpose</h2>
          <p>
            This Terms of Service (this “Agreement” or the “Terms of Service”) between you, a User and URoom Inc., a company organized under
            the laws of the Republic of Korea (hereinafter the “Company,” “we,” “us” or “our") govern your use of our web based metaverse
            creation platform named 'OwnXR'(the “OwnXR Service(s)”, “OwnXR” or “Service(s)”). “You” or “Your” means you or any other person
            or entity identified in any service account on whose behalf you are authorized to act.
          </p>
          <h2 class="sub-title">2. Definition</h2>
          <p>2.1. In this Agreement, the following terms shall have the meanings specified in this Article 2.</p>
          <p>
            - “OwnXR Website” or “Website” means the website operated by the Company (www.ownxr.com or other website to be included later)
            for OwnXR Services.
          </p>
          <p>
            - “User” means a user of Service who has agreed to these Terms of Conditions and has completed the User registration. There are
            two (2) types of User: Personal User and Enterprise User.
          </p>
          <p>
            - "User Name"(ID) means email address or a combination of letters, numbers, and special symbols that are designated by the User
            and approved by the Company to identify the User and use the Service.
          </p>
          <p>
            - A “Password” means a secret code consists of alphabets and numeric numbers that a User can choose to protect the User’s access
            to OwnXR.
          </p>
          <p>- “Account” means a login account based on “User Name” and “Password”.</p>
          <p>
            - “Paid Service Agreement” means a separate agreement entered into by and between the Company and a User under which the Company
            provides Service to a User with a set of quantity, scope, rights and refund policy(the “Paid Service”) and the User pays a
            certain price therefor.
          </p>
          <p>
            - “Termination” means termination by a User of the Paid Service Agreement, meaning termination of the use of the Paid Service
            within Website. “Withdrawal” means a User’s signing out from the Website or terminating this Agreement.
          </p>
          <p>- “User Asset” means the metaverse contents created by a User utilizing “OwnXR”.</p>
          <p>
            - “User Space” means is a three-dimensional spatial asset that a User can edit and share, created through free subscription or
            subscription payment (Paid Service Agreement) of OwnXR. “User Asset” and “User Space” are collectively referred to as “User
            Assets,”
          </p>
          <p>
            - “Open Link” means a website or link owned or managed by a User that allows the User to share the Use Asset with others through
            “OwnXR”.
          </p>
          <p>
            2.2. Any terms or expressions used in this Agreement that are not otherwise defined in Article 1 shall be interpreted in
            accordance with Personal Information Protection Act and other applicable laws, any instructions using the OwnXR Services
            provided by the Company or business customs.
          </p>
          <h2 class="sub-title">3. Applicability and Amendment</h2>
          <p>3.1. This Agreement applies to all Users of OwnXR.</p>
          <p>
            3.2. By creating a User account via the online user interface on the Website, or by otherwise accessing or using any part of the
            OwnXR Services, you effectively give your consent to be bound by the terms and conditions of this Agreement.
          </p>
          <p>
            3.3. If you do not agree to the terms and conditions of this Agreement, you cannot create a User account, or access or use any
            part of the OwnXR Services.
          </p>
          <p>
            3.4. We reserve the right to change or modify any of the terms and conditions of this Agreement at any time and in our sole
            discretion. We will provide 15 days prior notice to you before making any such changes; provided, if in our opinion, any such
            changes may not be so favorable to you, then in such cases, we will provide at least 30 days prior notice to you on Website home
            page or via email or SMS at the Company’s option (However, if we cannot reach you due to failure on your part to update your
            contact information, then a prior notice will be deemed given to you by posting the amended terms and conditions on the
            Website).
          </p>
          <p>
            3.5. Your continued use of any part of the OwnXR Service following our notice to you of any changes or modification to any of
            the terms and conditions of this Agreement, or posting of the amended Agreement on the Website will constitute your consent to
            such changes or modifications. If you do not agree with any of the changes or modifications to this Agreement, you must stop
            using the OwnXR Services and contact us for cancellation of your membership.
          </p>
          <p>
            3.6. By agreeing to be bound by the terms and conditions of this Agreement, you also agree to periodically check our Website for
            any amendment to this Agreement. The Company is not responsible for any damages or losses you may incur due to your failure to
            check for the updates or changes to this Agreement.
          </p>
          <h2 class="sub-title">4. Other Applicable Terms and Conditions</h2>
          <p>
            4.1. Any matters not specifically addressed in this Agreement shall be governed by the provisions of applicable Korean laws,
            including without limitation, the Personal Information Protection Act and the Act on the Consumer Protection in Electronic
            Commerce, etc.
          </p>
          <p>
            4.2. If you, as a User entered into a separate agreement such as Paid Service Agreement or a separate terms of service with
            OwnXR concerning any other product or services offered by the Company through OwnXR(the “Separate Agreement”), then the terms
            and conditions of such Separate Agreement shall govern the relationship between the parties concerning that other subject matter
            in addition to this Agreement.
          </p>
          <h2 class="priority-title">AGREEMENT FOR USING OWNXR SERVICE</h2>
          <h2 class="sub-title first">5. Sign Up</h2>
          <p>
            5.1. A person who intends to use the Service (hereinafter referred to as "Applicant") shall subscribe to the Service, and apply
            for subscription by checking the checkbox indicating that the "Applicant" agrees to the terms and conditions. This Agreement is
            concluded by the Company's acceptance of the application of the “Applicant”. In this case, the “Applicant” will be deemed to
            have agreed to the following.
          </p>
          <p>- Agree to these Terms of Service.</p>
          <p>- Recognize and agree that sanctions may be taken in violation of these Terms of Service.</p>
          <p>5.3. The Company may, if deemed necessary, have the applicant submit a separate document for joining.</p>
          <p>
            5.4. In the following cases, the Company may or may not withhold the acceptance of the subscription, or may terminate this
            Agreement afterwards.
          </p>
          <p>- If the User with whom the Company previously terminated this Agreement applies for re-enrollment</p>
          <p>- If the User who has withdrawn under these Terms of Service has applied for the same email address again within 30 days</p>
          <p>- If the non-real name or others’ name is used for application</p>
          <p>- If false information is entered in the application form or the information requested by the Company is not entered</p>
          <p>
            - If the application is made for the purpose of imitating or stealing the Service or obtaining specific information about the
            Service
          </p>
          <p>
            - Application is not acceptable due to the fault of the “Applicant” or User or application is in violation of other regulations
          </p>
          <p>- If the Service or Website is used for the violation of this Agreement or applicable laws</p>
          <p>
            - If it is deemed that it may have a negative impact on the provision of OwnXR Service, such as interfering with other Users’
            use of the Service or stealing the information.
          </p>
          <p>
            - Other cases where the Company clearly acknowledges that it may be harmful to other "Users" or contrary to social order and
            morals based on relevant laws and regulations.
          </p>
          <p>
            5.5. The Company may withhold approval if there is no room for service-related facilities, or if there is a technical or
            business problem in providing the service, or if the Company deems it is financially or technically necessary to do so.
          </p>
          <p>
            5.6. If the acceptance of subscription is withheld in accordance with Paragraph 4, the Company may notify such withholding to
            the email address entered by the “Applicant”.
          </p>
          <p>
            5.7. The date of entering into the Agreement shall be the time when the Company marked the completion of the subscription in the
            application process by sending email or SMS.
          </p>
          <p>
            5.8. The Company can give a grade to User according to company policy, and can differentiate the level of service according to
            the grade.
          </p>
          <h2 class="sub-title">6. Change of User Information</h2>
          <p>
            6.1. The User can access his/her user information at any time through the settings and My Page menu, etc., and can modify the
            information by inputting on the page or requesting the Company.
          </p>
          <p>
            6.2. If the User changes the information specified in the subscription application, he/she shall inform the Company of the
            change, and the Company shall not be responsible for any disadvantages caused by not notifying the Company of the change.
          </p>
          <p>
            6.3. The User shall notify the Company without delay or register the change by changing his/her personal information without
            delay if any matters specified in the application for registration, such as contact means (e-mail, mobile phone number), are
            changed. The Company shall not be liable for any damage caused by such omission.
          </p>
          <h2 class="sub-title">7. Collection and Protection of Personal Information</h2>
          <p>7.1. The Company collects the personal information necessary from the User to provide the Service.</p>
          <p>
            7.2. The Company shall endeavor to protect the personal information of the User collected under Paragraph 1. Regarding the
            protection and use of personal information, relevant laws and the company's privacy policy shall apply.
          </p>
          <p>7.3. Privacy policy of the Company does not apply to linked sites other than the Service of the Company.</p>
          <h2 class="sub-title">8. Responsibility for Managing “User Name”</h2>
          <p>
            8.1. The User is responsible for the management of "User Name(ID) "and "Password" of the User, and the User is responsible for
            all disadvantages if the third party uses it due to the User's intention or negligence including mismanagement.
          </p>
          <p>
            8.2. When the User recognizes that the “User Name(ID)”, “Password” and additional information are stolen or used by a third
            party, he/she shall immediately notify the Company and follow the instructions of the Company.
          </p>
          <p>
            8.3. In the case of Paragraph 2, the Company shall not be responsible for any disadvantages caused by not notifying the Company
            or not following the instructions of the Company even if notification of the User to the Company exists.
          </p>
          <p>
            8.4. The Company can restrict the use of the “User Name” when the "User Name" of the User has the concern of the leakage of
            personal information, contradicts social norms or morals, or maybe mistaken as the Company or the Company's administrator.
          </p>
          <p>8.5 The User may not change the “User Name(ID)” without Company’s prior consent.</p>
          <h2 class="sub-title">9. Use of Service</h2>
          <p>9.1. The Service provided by the Company is as follows:</p>
          <p>(1) Support the creation, edit or management of User Assets by the User by using web-based OwnXR</p>
          <p>
            (2) Support User communicating and sharing the User Assets with other Users within the “OwnXR” Website or through “Open Link”
          </p>
          <p>(3) Storage and management of User Assets within OwnXR Website</p>
          <p>(4) Support licensing, acquisition, purchase or management of the User Assets and related setting functions</p>
          <p>(5) Create and provide or sell applications necessary for creating, editing, and uploading “User Assets.”</p>
          <p>
            (6) Any other services provided by the Company to the User through further development or partnership agreements with other
            companies.
          </p>
          <p>
            9.2. Service can be used immediately after the Company approval. However, if you do not complete email verification, there may
            be restrictions on the use of some or all of the Services.
          </p>
          <p>9.3. The Paid Service will be provided by the Company to the User on the following conditions:</p>
          <p>
            - Paid Service(s) comprises of a paid subscription service for individual members (Pro Plan) that provides all features of
            “OwnXR”, a paid services customized for enterprise needs (Enterprise Plan), and a paid service for asset production services
            (Customization).
          </p>
          <p>
            - Paid services will provided in accordance with the terms of the Paid Service Agreement once the Use has completed payment.
          </p>
          <p>- Service fees for Paid Services can be made through credit card or through payment processors such as Stripe or Paypal..</p>
          <p>
            - The price of Paid Services will be determined based on the Company's reasonable judgment and decisions in business and
            management. Even if the content of the Service is similar, the price may differ for each User, and even if the price is the
            same, there may be differences in the content of the Service for each User.
          </p>
          <p>
            - The Company may change the price of Paid Services depending on operational and technical needs, and in such cases, a prior
            notice will be provided to Users.
          </p>
          <p>
            - With regard to the refunds for Paid Services or termination of Paid Service Agreement, the provisions of the Paid Service
            Agreement concluded by the User take precedence over these Terms of Service.
          </p>
          <p>
            9.4 Services other than Paid Service (the “Free Services”) will be provided on “AS IS” basis, for which the Company does not
            provide any kind of warranty.
          </p>
          <p>
            9.5 The Company may provide Free Services to all or part of the User at the Company’s discretion and decision for publicity and
            promotion purposes, and terminate the provision of Free Services at any time.
          </p>
          <p>9.6. The User's right to use the Service may not be transferred, given or used for a pledge.</p>
          <p>
            9.7. If it is judged that the use of the Service by the User is not in accordance with the normal procedure, the Company may
            intervene and take actions in place of the User, and such judgment is entirely up to the Company.
          </p>
          <h2 class="sub-title">10. Service Usage Time</h2>
          <p>
            10.1. The Service usage time shall be in principle 24 hours a day, 7 days a week (00: 00-24: 00) except when the Company cannot
            provide the Service for technical or business reasons.
          </p>
          <p>
            10.2. The Company may temporarily suspend the provision of Services if there are reasons for its operation, such as regular
            inspection, replacement and failure of service facilities, or loss of communication. If the interruption of service provision
            exceeds 24 hours, the Company will notify the User in advance according to Article 11. If there is an unavoidable reason that
            cannot be notified in advance, it will be notified after the event.
          </p>
          <p>
            10.3. The Company may conduct regular inspection when it is deemed necessary to provide the Service, and the regular inspection
            time will be announced through the Website home page.
          </p>
          <p>
            10.4 Online inquiries during the Service may not be available 24 hours a day depending on the personal circumstances of the
            Administrator.
          </p>
          <h2 class="sub-title">11. Notice to the User</h2>
          <p>
            11.1. If the Company notifies the User, it can be done by e-mail of the User registered in the Service unless otherwise
            specified in this Agreement.
          </p>
          <p>
            11.2. In the case of Paragraph 1, the Company shall not be responsible if the User does not receive e-mail authentication or if
            he/she inputs false e-mail intentionally or by mistake.
          </p>
          <p>
            11.3. In case of notification to the whole Users, the Company may substitute the notice of Paragraph 1 by posting it on the
            Website home page by other notice for 7 days or more.
          </p>
          <p></p>
          <h2 class="sub-title">12. Changes in Services</h2>
          <p>
            12.1. We reserve the right to change or modify any part of the OwnXR Services (including without limitation, quality and
            technical specifications) at any time and in its sole discretion upon providing 15 days prior notice to you which notice will be
            posted on the Website home page or communicated via e-mail. If, however, we believe any of the changes may not be so favorable
            to you, then we will provide at least 30 days prior notice to you via e-mail or SMS.
          </p>
          <p>12.2. We reserve the right to suspend or terminate the OwnXR Services or any part thereof in each of the following cases:</p>
          <p>- for maintenance, repair or technical difficulties;</p>
          <p>- if a User interferes with our operation;</p>
          <p>- for power outage, failure of communication equipment or sever failure due to unusually high traffic;</p>
          <p>- for any other internal reasons as determined by us; or</p>
          <p>- in the event of force majeure, including without limitation, natural disaster, acts of God or national emergency.</p>
          <p>
            12.3. A User may terminate this Agreement at any time with or without cause by making termination request to the Company. A User
            who wishes to terminate this Agreement may apply for termination of use of Service or sign out from the Website on the Website.
          </p>
          <p>
            - The User shall be responsible for any disadvantages resulting from such termination of this Agreement or withdrawal from the
            Website. In that case, the Company reserves the right to retract any rights and privileges that the Company offered to the User
            prior to such termination or withdrawal.
          </p>
          <p>
            12.4. The Company will not be responsible for any losses, damages or problems you may incur in relation to your termination or
            suspension of the use of the OwnXR Services.
          </p>
          <p>
            12.5. The Company reserves the right to immediately terminate this Agreement or block such User from accessing OwnXR in its sole
            discretion for any breach by the User of its obligations under Article 14, with or without notice, upon discovery by the
            Company.
          </p>
          <p>
            12.6. Any User whose account was terminated by the Company under Paragraph 5 above may appeal the Company’s decision by
            following the instructions provided by the Company.
          </p>
          <p>12.7. If the Company finds the appeal has valid ground, the Company will immediately resume the provision of Service.</p>
          <p>
            12.8. If the Company determines that the Service cannot be used due to reasons such as the death of the User, the Company may
            terminate this Agreement.
          </p>
          <p>
            12.9. In the case of a User who has been inactive for a period of 1 years or more, the Company may restrict the use of the
            Service, or terminate this Agreement by notifying the termination of this Agreement on the Website by email.
          </p>
          <p></p>
          <h2 class="priority-title">RIGHTS AND OBLIGATIONS OF THE PARTIES</h2>
          <h2 class="sub-title first">13. Obligations of the Company</h2>
          <p>
            13.1 The Company will not engage in any acts that violate the law or these Terms of Service, will do its best to provide
            continuous and stable services pursuant to these Terms of Service.
          </p>
          <p>
            13.2. The Company does not disclose, share or distribute any personal information collected from any User to a third party
            without the User’s express consent; provided, that we may disclose such information to relevant government agencies, including
            without limitation, the Korea Communications Commission pursuant to a legal requirement, a judicial order or government
            regulations, to the extent required under applicable laws.
          </p>
          <p>
            13.3 The Company operates a security system appropriate to protect Users’ personal information (including credit information) so
            that Users can use the Service safely.
          </p>
          <p>
            13.4. The Company will respond in due process to any complaints reported to the customer service department. If it takes time
            for the Company to respond to complaint, the Company will communicate reasons for the delay and estimated time of response
            through the Website or via email.
          </p>
          <p>
            13.5. The Company closely observes all applicable laws for providing and maintaining the OwnXR Services, including without
            limitation, the Information and Communications Network Act, and the Protection of Communications Secret Act.
          </p>
          <h2 class="sub-title">14-1. Obligations of the User</h2>
          <p>14-1.1. The User shall not:</p>
          <p>(1) Provide false information when applying for subscription or changing the User information</p>
          <p>(2) Theft of others’ information or allow a third party to use the User’s personal information</p>
          <p>
            (3) Change the information posted on the Service of the Company or use the information obtained by using the Service for
            reproduction, processing, translation or reproduction, performance, broadcasting, exhibition, distribution publication, etc. for
            commercial or non-profit purposes or offering to third parties without the prior consent of the Company.
          </p>
          <p>
            (4) Receive money or transfer the right to use the Services or receive money in return by providing a third party with the
            opportunity to promote the User or promoting a third party on behalf of a third party using the “Services” provided by the
            Company.
          </p>
          <p>
            (5) Infringe on the rights of the Company or third parties, such as posting false facts about the Company or other third
            parties, or infringing on intellectual property rights.
          </p>
          <p>(6) Use unfairly the Service by stealing the User Name(ID) and Password of other Users.</p>
          <p>
            (7) Use the Company’s Paid Services by using payment information of others without the permission of others, such as account
            numbers and credit card numbers of others.
          </p>
          <p>
            (8) Post any content that suggests joining a pyramid or terrorist organization or post obscene or violent messages, postings or
            voices, or disclose or posting information that is against public order or morals.
          </p>
          <p>
            (9) Transmit or post information (computer program, etc.) that is prohibited from being transmitted or posted by related laws
            such as the Information and Communication Network Act.
          </p>
          <p>(10) Post harmful media for juveniles under the Juvenile Protection Act</p>
          <p>(11) Disseminate information, sentences, figures, sounds, etc., in violation of public order or morals</p>
          <p>
            (12) Post or send messages by pretending or impersonating an employee of the Company or a manager of the Services or by using
            the name of another person.
          </p>
          <p>
            (13) Post or email any materials containing software viruses or other computer codes, files or programs designed to disrupt or
            destroy the normal operation of computer software, hardware or telecommunications equipment.
          </p>
          <p>(14) Interfere with other “Users”’ use of the Service, such as stalking, abusive language, or plastering.</p>
          <p>(15) Collect, store or disclose the personal information of other “Users” without their consent.</p>
          <p>
            (16) Collect information from other “Users” to post advertisements or propaganda or send spam mails to an unspecified majority;
          </p>
          <p>(17) Modify, reverse engineer, decompile, or disassemble software provided by the Company.</p>
          <p>
            (18) Use the Company’s intellectual property without permission, including the Service description or terms and conditions of
            the Company, without the Company’s consent.
          </p>
          <p>(19) Provide information in the “Services” to competitors or potential competitors of the Company;</p>
          <p>(20) Access to information that is not open to “Users”, such as server computer of the Company</p>
          <p>
            (21) Attempt an individual transaction other than the Service which is not agreed with the Company in advance by exposing
            personal information or exposing personal contacts.
          </p>
          <p>
            (22) Violate current laws, terms and conditions set forth in the “Services” provided by the Company, and other regulations
            regarding the use of the “Services”.
          </p>
          <p>(23) Interfere with any other User’s use of the “Services” or the provision of the “Services” by the Company.</p>
          <p>(24) Act other actions that may be harmful to the Company and the “Services”</p>
          <p>
            14-1.2. If a User commits an act that violates Article 14-1.1, the Company may delete or temporarily delete the relevant
            postings and may take measures such as restricting the use of the Service, terminating this Agreement, debarring permanently, or
            filing a civil or criminal complaint. If the Company incurs damages, compensation may be claimed.
          </p>
          <h2 class="sub-title">14-2 Transaction of User Assets between Users</h2>
          <p>14-2.1 Seller’s Obligations</p>
          <p>
            If a User wishes to sell (i.e. permit to use) “User Assets” to another User (the selling User is called the “Seller” and the
            purchasing User is called the “Buyer”), the Company shall organize such transaction through OwnXR website.
          </p>
          <p>“Seller” must comply the following obligations:</p>
          <p>
            (1) “Seller” must directly register ‘User Assets’ in the OwnXR editor and upload them to the marketplace placed in the
            “Website”. The price of “User Asset” shall be determined by the ‘Seller’ on its own, taking into account the marketplace fees,
            etc., and the Company will not involve in such procedure. However, the Company may enforce applicable policies to prevent any
            damages caused to the “Buyer” during the transaction process.
          </p>
          <p>
            (2) In case there is a problem with the “User Assets” sold by the “Seller”, the “Seller” shall be responsible to correct or
            update corresponding “User Asset”.
          </p>
          <p>
            (3) “Seller” must comply with the obligations or requirements set forth in the Act on Consumer Protection in Electronic Commerce
            during any transaction of “User Assets”.
          </p>
          <p>
            (4) “Seller” is prohibited from selling “User Assets” directly to “Buyers” without going through the Company’s “Website,” and in
            case any violation to such act is discovered, the Company may suspend the “Seller’s” use of the marketplace or terminate this
            Service Agreement.
          </p>
          <p>The “Seller” must provide warranty services for his/her “User Assets”, at its own responsibility and expense.</p>
          <p>
            (5) “Seller” has the obligation to respond faithfully and accurately to the inquiries from “Buyers”. In case any damages occur
            to the “Buyer” due to the “Seller’s” inaccurate response, the “Seller” is responsible for such damages.
          </p>
          <h2 class="priority-title">GENERAL PROVISIONS</h2>
          <h2 class="sub-title first">15. Attribution of Rights</h2>
          <p>15.1. Copyright and intellectual property rights of the Service belong to the Company.</p>
          <p>
            15.2. The Company grants the User only the right to use Account, “User Name”, content, etc. in connection with Service according
            to the terms and conditions set by Company, the User is not allowed to transfer, to sell, to resell, or to provide collateral
            the right to use.
          </p>
          <h2 class="sub-title">16. Copyrights to the Postings and User Assets</h2>
          <p>
            16.1 The rights to all materials posted on the Website or User Assets produced by Users (including transmission between Users
            and sharing of links by Users) (the “Postings”) belong to the User who posted them, and the Company may not use them for
            commercial purposes without the consent of the posted User. However, content such as materials or information such as signs,
            letters, shapes, colors, voices, sounds, images and videos (including combinations thereof) provided by the Company or any
            third-party used in the production of “User Assets” shall be excluded.
          </p>
          <p>
            16.2 The User shall assume any liabilities arising from Postings and User Assets posted or produced by User within the Service
            that infringe upon the copyrights of others. If the Company receives an objection, such as a claim for damages, from another
            person on the grounds that the User has infringed on other's copyright, etc., the User shall indemnify the Company and
            compensate for all damages incurred by the Company.
          </p>
          <h2 class="sub-title">17. Refunds</h2>
          <p>
            17.1. In the event of an over or mistaken payment by the User, the Company will refund the corresponding amount. In such a case,
            the fee incurred as a result of the refund procedure shall be borne by the User.
          </p>
          <p>
            17.2. In case of a refund due to the responsibility of the User, such as change of mind, the User shall be responsible for any
            fees incurred due to the refund process.
          </p>
          <p>17.3. Notwithstanding the provisions of Paragraph 2, the following items will be refunded in full:</p>
          <p>1) If the User has not used the Service within 24 hours after completing the payment</p>
          <p>2) If the Service is not available due to the Company fault, such as Service failure</p>
          <p>
            - In case of refund for item 1), the User shall be responsible for the fees incurred. In the case of a refund for item 2), the
            Company shall bear the fee incurred.
          </p>
          <p>
            17.4. If the User violates the provisions of Article 14 and the Company restricts the use of the Service by the User or
            unilaterally terminates this Agreement, the Company shall not be responsible to refund equivalent to the damages incurred by the
            User.
          </p>
          <p>
            17.5. When the User terminates this Agreement or withdraws from the Service, the User must request a refund. If the User
            terminates this Agreement or withdraws from the Service without requesting a refund, the Company has no obligation to refund.
          </p>
          <p>
            17.6. Refunds should be made using the payment method used by the User, but if this is impossible, the refund shall be made in a
            separate method determined by the Company.
          </p>
          <p>
            17.7. In principle, the Company shall process the refund within 7 business days. If a delay occurs due to the delay of
            processing by a third party such as credit card company, Stripe or the cause of the User, the corresponding days are not
            included in the processing period.
          </p>
          <h2 class="sub-title">18. Promotional Materials</h2>
          <p>
            18.1. The Company will, from time to time, provide relevant information about OwnXR on the Website or via email or postal mail
            to our Users.
          </p>
          <p>18.2. Company will, with your consent, provide useful information or other advertising materials to you via SMS or email.</p>
          <h2 class="sub-title">19. Liabilities</h2>
          <p>
            19.1. The Company agrees to indemnify and hold harmless a User from any damages or losses arising out of or resulting from any
            intentional misconduct of the Company in providing the OwnXR Service.
          </p>
          <p>
            19.2. A User agrees to indemnify and hold harmless the Company, its officers, employees and agents from any claims, lawsuits,
            damages or losses including court costs and attorney’s fee arising from any breach by the User of this Agreement or any
            provisions of applicable laws.
          </p>
          <p>
            19.3. You agree to indemnify, defend and hold the Company, its agents, directors, officers, and employees harmless from and
            against any and all third-party claims, liability, loss, and expense (including damage awards, settlement amounts, and
            reasonable legal fees), brought against any Indemnified Party, arising out of, related to or which may arise from your breach of
            any term of this Agreement your failure to comply the applicable laws.
          </p>
          <h2 class="sub-title">20. DISCLAIMER</h2>
          <p>
            20.1. WE ARE NOT RESPONSIBLE FOR ANY LOSS OR DAMAGE FROM THE USE OF THE SERVICE WHICH IS NOT CAUSED BY WILLFUL OR GORSS
            NEGLIGENCE OF THE COMPANY.
          </p>
          <p>
            20.2. WE ARE NOT RESPONSIBLE FOR THE INTERRUPTION OF THE SERVICES CAUSED BY EVENTS BEYOND OUR CONTROL SUCH AS STRIKES, RIOTS,
            INSURRECTION, FIRES, FLOODS, EXPLOSIONS, WAR, GOVERNMENTAL ACTION, LABOR CONDITIONS, EARTHQUAKES, NATURAL DISASTERS.
          </p>
          <p>20.3. WE ARE NOT RESPONSIBLE FOR THE INTERRUPTION OF THE SERVICES CAUSED BY USER’S FAULT.</p>
          <p>
            20.4. WE ARE NOT RESPONSIBLE FOR LOSS OF PROFIT EXPECTED FROM THE USE OF THE SERVICES; WE ARE NOT RESPONSIBLE FOR LOSS OR DAMAGE
            CAUSED BY THE MATERIALS OBTAINED FROM THE SERVICE.
          </p>
          <p>
            20.5. WE ARE NOT RESPONSIBLE FOR THE RELIABILITY OR THE ACCURACY OF THE CONTENTS CONTAINED IN THE POSTONGS UPLOADED BY THE USER.
          </p>
          <p>
            20.6. WE ARE NOT RESPONSIBLE FOR ANY DAMAGES OR LIABILITIES RELATED TO USER ASSETS THAT ARISE DUE TO WILLFUL MISCONDUCT OR
            NEGLIGENCE.
          </p>
          <p>
            20.7. WE ARE NOT RESPONSIBLE AND WILL NOT GET INVOLVED OR MEDIATE ANY DISPUTE (A) BETWEEN OR AMONG OUR USERS OR (B) BETWEEN YOU
            AND A THIRD PARTY WHICH MAY ARISE IN RELATION TO USE OF THE SERVICE.
          </p>
          <p>
            20.8. WE CLAIM NO RESPONSIBILTIY FOR ANY LOSSES OR DAMAGES INCURRED BY YOU IN CONNECTION WITH YOUR DISCLOSURE OR RELEASE OF ANY
            PERSONAL INFORMATION ABOUT YOU.
          </p>
          <h2 class="sub-title">21. No Assignment</h2>
          <p>
            You may not assign or transfer this Agreement or any of your rights and obligations hereunder without the prior consent of the
            Company.
          </p>
          <h2 class="sub-title">22. Governing Law; Dispute Resolution</h2>
          <p>
            22.1 This Agreement and any action related thereto will be governed by the laws of the Republic of Korea without regard to or
            application of its conflict of law provisions.
          </p>
          <p>
            22.2 If a dispute arises between the Company and the User in connection with the use of the “OwnXR” Service, the parties shall
            have good faith consultation to resolve such dispute. Without such consultation, a party may not file a lawsuit.
          </p>
          <p>
            22.3 If the parties fail to settle a dispute by consultation pursuant to Article 22.2, any disputes, claims or controversies
            arising out of or relating to this Agreement shall be brought before the Seoul Central District Court and you consent to the
            exclusive jurisdiction of such court.
          </p>
          <br />
          <p>This Terms of Service is effective as of October 13th, 2023.</p>
        </div>
      </div>
    }
  </section>
  <section class="footer" [class.none]="isScrolling">
    <div class="legal-info">
      <div class="terms" (click)="openDetails('terms-of-use')">{{ 'accountPanel.footer.terms' | translate }}</div>
      <div class="privacy-policy" (click)="openDetails('privacy-policy')">{{ 'accountPanel.footer.privacyPolicy' | translate }}</div>
    </div>
  </section>
</div>
