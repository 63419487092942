import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'm-account-panel-information',
  templateUrl: './m-account-panel-information.component.html',
  styleUrls: ['./m-account-panel-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoblieAccountPanelInformationComponent implements OnInit {
  @Input() description = '';
  openedDetails$ = this._accountPanelService.openedDetails$;

  constructor(private _accountPanelService: AccountPanelService) {}

  ngOnInit(): void {}

  onClose() {
    this._accountPanelService.closePanel();
  }

  onBack() {
    this._accountPanelService.closeDetails();
  }
}
