import { ReleaseLogService } from '@/data/src/lib/services/release-log.service';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'm-account-panel-release-log',
  templateUrl: './m-account-panel-release-log.component.html',
  styleUrls: ['./m-account-panel-release-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAccountPanelReleaseLogComponent implements OnInit {
  releaseLog$ = this._releaseLogService.releaseLog$;

  openedVersion: number | null = null;
  currentLanguage = this._translateService.currentLang;

  constructor(
    private _accountPanelService: AccountPanelService,
    private _releaseLogService: ReleaseLogService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {}

  onClose() {
    this._accountPanelService.closePanel();
  }

  onBack() {
    this._accountPanelService.closeDetails();
  }

  openVersion(i: number) {
    this.openedVersion = this.openedVersion === i ? null : i;
  }
}
