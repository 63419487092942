@if (headerType === 'landing') {
  <div class="header">
    <div class="icon icon-ownxr lg"></div>
    @if (currentLanguage$ | async; as currentLang) {
      <ng-select
        class="custom"
        [searchable]="false"
        [clearable]="false"
        placeholder="{{ 'shared.fields.selectPlaceholder' | translate }}"
        dropdownPosition="bottom"
        [ngModel]="currentLang"
        (ngModelChange)="onLanguageSelect($event)"
      >
        @for (language of languageOptions; track language) {
          <ng-option [value]="language.name">
            <div class="select-option-wrapper">
              <div class="text">{{ 'shared.fields.language.' + language.displayName | translate | uppercase }}</div>
              <div class="flag {{ language.name }}"></div>
            </div>
          </ng-option>
        }
      </ng-select>
    }
  </div>
} @else {
  <img class="ownxr-letter" src="../../../../assets/images/img-icon-ownxr-logo.png" />
  <section class="thumbnail-section">
    @if (!account) {
      <div class="icon-wrapper" (click)="loginRedirect()">
        @if (!account) {
          <div class="icon icon-account"></div>
        }
      </div>
    } @else {
      <div class="icon-wrapper" (click)="openPanel($event)">
        @if (!account.Thumbnail) {
          <div class="icon icon-account"></div>
        }
        @if (account.Thumbnail) {
          <img class="user-thumbnail" [src]="account.Thumbnail" />
        }
      </div>
    }
  </section>
}

@if (isPanelOpened$ | async) {
  <m-account-panel></m-account-panel>
}
