import { Component, OnDestroy, OnInit, Inject, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, distinctUntilChanged, interval, Observable, take } from 'rxjs';
import { JoystickManagerOptions } from 'nipplejs';

import { RouteParam } from '@/data/src/lib/enums/route-param';
import { XRScene } from '@/data/src/lib/models/data/scene';
import { SceneService } from '@/data/src/lib/services/scene.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { ApplicationService } from '../app.service';
import { PlayerService } from '../services/player.service';
import { ScenePlan } from '@/data/src/lib/enums/pricing-plan';
import { UrlService } from '@/data/src/lib/services/url.service';
import { SessionStorageService } from '@/data/src/lib/services/session-storage.service';
import { ViewMode } from '@/data/src/lib/view-manager';
import { PlatformDetail, SessionStorageKeys } from '@/data/src/lib/enums/storage-keys';
import { JoystickEvent } from '@/ui/src/lib/components/joystick/joystick.component';

enum URoomScenes {
  ko = '41ba3a31-18f2-4b1c-8beb-f00937775b82',
  en = '00a2c76a-14cb-462f-a330-cbe81a5491a4',
}

@UntilDestroy()
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  scene: XRScene | undefined;
  hoverActive$: Observable<boolean>;
  private sceneId: string;
  private elem: any;
  private isFullScreen: boolean;
  supportsXR = false;
  isHandheld = false;
  isDesktop = this._sessionStorageService.getItem(SessionStorageKeys.PLATFORM) === PlatformDetail.DESKTOP ? true : false;
  favIcon: HTMLLinkElement | null = this._document.querySelector('#appIcon');
  showCloseIcon = false;

  dynamicOptions: JoystickManagerOptions = {
    mode: 'static',
    multitouch: true,
    position: { left: '50%', top: '50%' },
  };

  plan = ScenePlan;

  get scenePlan() {
    return this.scene?.Plan;
  }

  constructor(
    private _accountService: AccountService,
    private _sceneService: SceneService,
    private _appService: ApplicationService,
    private _playerService: PlayerService,
    private _deviceService: DeviceDetectorService,
    private _router: Router,
    private _activedRoute: ActivatedRoute,
    private _title: Title,
    private _urlService: UrlService,
    private _sessionStorageService: SessionStorageService,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    combineLatest([
      this._activedRoute.paramMap,
      this._accountService.language$.pipe(distinctUntilChanged()),
      this._urlService.urlTransformResult$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(async (args) => {
        if (!args[2].isInitialValue) {
          this.scene = undefined;

          if (args && args[0] && args[1]) {
            this._ngZone.runOutsideAngular(() => {
              interval(10)
                .pipe(take(1), untilDestroyed(this))
                .subscribe(async () => {
                  if (this._urlService.urlTransformResult.sceneId !== '') {
                    this.sceneId = this._urlService.urlTransformResult.sceneId;
                  } else {
                    this.sceneId = args[0].get(RouteParam.Scene) ?? URoomScenes[args[1]];
                    this.showCloseIcon = args[0].get(RouteParam.Scene) ? true : false;
                  }
                  this.scene = await this._sceneService.getPublishedVersion(this.sceneId);
                  if (this.scene) {
                    this.favIcon!.href = this.scene.Favicon ? this.scene.Favicon : '../../assets/oxr-favicon.png';
                    this._title.setTitle(this.scene.Plan === ScenePlan.Free ? 'OWNXR | ' + this.scene.Name : this.scene.Name);
                  }
                  this._ngZone.run(() => {});
                });
            });
          }
        }
      });

    this._appService.xrSupportSubject.pipe(untilDestroyed(this)).subscribe((supportsXR) => {
      this.supportsXR = supportsXR;
    });

    this.hoverActive$ = this._appService.hoverActive$;
    this.isHandheld = !this.isDesktop;
  }

  get isPortrait() {
    return this._deviceService.orientation.includes('portrait');
  }

  ngOnDestroy(): void {
    this._title.setTitle('OWNXR');
    this.favIcon!.href = '../../assets/oxr-favicon.png';
  }

  ngOnInit(): void {
    this.elem = this._document.documentElement;
  }

  close() {
    this._router.navigate(['community']);
  }
  onMoveDynamic(event: JoystickEvent) {
    //this.dynamicOutputData = event.data;
    const force = Math.min(event.data.force * 0.5, 2);
    this._playerService.setMovement(event.data.vector.x * force, event.data.vector.y * force);
  }
  stopMove() {
    this._playerService.setMovement(0, 0);
  }

  onJump() {
    this._playerService.setJump();
  }

  onExit() {
    this._router.navigate(['community', 'list']);
  }

  toggleFullScreen() {
    if (this.isFullScreen) {
      this.closeFullscreen();
      this.isFullScreen = false;
    } else {
      this.openFullscreen();
      this.isFullScreen = true;
    }
  }

  setVRMode() {
    this._appService.setViewMode(ViewMode.VR);
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this._document.exitFullscreen) {
      this._document.exitFullscreen();
    }
  }
}
