import { NgModule } from '@angular/core';
import { DragScrollModule } from 'ngx-drag-scroll';
import { QuillModule } from 'ngx-quill';
import { DataModule } from '@/data/src/public-api';
import { UiModule } from 'projects/ui/src/public-api';
import { MobileSharedModule } from '../shared.module';
import { MobileHeaderComponent } from './layout/m-header/m-header.component';
import { MobileDropdownComponent } from './m-dropdown/m-dropdown.component';
import { MobileContactFormToolComponent } from './tools/m-contact-form-tool/m-contact-form-tool.component';
import { MobileGalleryToolComponent } from './tools/m-gallery-tool/m-gallery-tool.component';
import { MobileGoogleFormToolComponent } from './tools/m-google-form-tool/m-google-form-tool.component';
import { MobileGuestbookCommentComponent } from './tools/m-guestbook-tool/m-guestbook-comment/m-guestbook-comment.component';
import { MobileGuestbookToolComponent } from './tools/m-guestbook-tool/m-guestbook-tool.component';
import { MobileTextToolComponent } from './tools/m-text-tool/m-text-tool.component';
import { MobileVideoToolComponent } from './tools/m-video-tool/m-video-tool.component';
import { MobileAccountPanelComponent } from './layout/m-account-panel/m-account-panel.component';
import { MobileAccountPanelTermsOfUseComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-terms-of-use/m-account-panel-terms-of-use.component';
import { MobileAccountPanelPrivacyPolicyComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-privacy-policy/m-account-panel-privacy-policy.component';
import { MobileAccountPanelUserInfoComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-user-info/m-account-panel-user-info.component';
import { MobileAccountPanelLanguageComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-language/m-account-panel-language.component';
import { MoblieAccountPanelInformationComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-information/m-account-panel-information.component';
import { MobileAccountPanelReleaseLogComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-release-log/m-account-panel-release-log.component';
import { MobileAccountPanelSecurityComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-security/m-account-panel-security.component';
import { MobileAccountPanelWithdrawalComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-security/m-account-panel-withdrawal/m-account-panel-withdrawal.component';
import { MobileAccountPanelSubscriptionManagementComponent } from './layout/m-account-panel/m-account-panel-details/m-account-panel-subscription-management/m-account-panel-subscription-management.component';

@NgModule({
  imports: [DataModule, UiModule, MobileSharedModule, DragScrollModule, QuillModule],
  declarations: [
    MobileHeaderComponent,
    MobileDropdownComponent,
    MobileTextToolComponent,
    MobileGalleryToolComponent,
    MobileVideoToolComponent,
    MobileContactFormToolComponent,
    MobileGuestbookToolComponent,
    MobileGuestbookCommentComponent,
    MobileGoogleFormToolComponent,
    MobileAccountPanelComponent,
    MobileAccountPanelTermsOfUseComponent,
    MobileAccountPanelPrivacyPolicyComponent,
    MobileAccountPanelUserInfoComponent,
    MobileAccountPanelLanguageComponent,
    MoblieAccountPanelInformationComponent,
    MobileAccountPanelReleaseLogComponent,
    MobileAccountPanelSecurityComponent,
    MobileAccountPanelWithdrawalComponent,
    MobileAccountPanelSubscriptionManagementComponent,
  ],
  exports: [
    MobileHeaderComponent,
    MobileDropdownComponent,
    MobileTextToolComponent,
    MobileGalleryToolComponent,
    MobileVideoToolComponent,
    MobileContactFormToolComponent,
    MobileGuestbookToolComponent,
    MobileGuestbookCommentComponent,
    MobileGoogleFormToolComponent,
  ],
})
export class MobileComponentsModule {}
