<div class="frame">
  <section class="header">
    {{ 'accountPanel.actions.' + (openedDetails$ | async) | translate }}
    <div class="icon-wrapper close" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>

  <section class="body">
    {{ description }}
  </section>

  <section class="footer">
    <button ui-button (click)="onBack()">
      <span>{{ 'shared.actions.back' | translate }}</span>
    </button>
  </section>
</div>
