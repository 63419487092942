import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { AccountService } from '@/data/src/lib/services/account.service';
import { Account } from '@/data/src/lib/models/data/account';
@UntilDestroy()
@Component({
  selector: 'm-account-panel-withdrawal',
  templateUrl: './m-account-panel-withdrawal.component.html',
  styleUrls: ['./m-account-panel-withdrawal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAccountPanelWithdrawalComponent implements OnInit {
  currentLanguage$ = this._accountService.language$;
  account: Account;

  reason: string;
  reasonOptions: string[] = ['changeEmail', 'lackContent', 'lowUsage', 'systemError'];

  constructor(private _accountPanelService: AccountPanelService, private _accountService: AccountService) {
    this._accountPanelService.account$.pipe(untilDestroyed(this)).subscribe((account) => (this.account = <Account>account));
  }

  ngOnInit(): void {}

  onClose() {
    this._accountPanelService.closePanel();
  }

  onBack() {
    this._accountPanelService.closeDetails();
  }

  sendFeedback(): void {
    this._accountPanelService.sendFeedback(this.reason);
  }

  deleteAccount(): void {
    this._accountPanelService.deleteAccount(this.reason);
  }
}
