<ng-template #panel [ngTemplateOutlet]="panel" let-account="account" [ngTemplateOutletContext]="{ account: account$ | async }">
  <div class="frame">
    <section class="header">
      <div class="icon-wrapper" (click)="onClose()">
        <div class="icon icon-close xs"></div>
      </div>
    </section>
    <section class="user-info">
      <input type="file" id="user-thumbnail" (change)="uploadFile($event)" />
      <div class="thumbnail-wrapper">
        @if (!account?.Thumbnail && !fileUploading) {
          <div class="user-thumbnail">
            <div class="icon icon-account primary lg"></div>
          </div>
        }
        @if (account?.Thumbnail && !fileUploading) {
          <img class="user-thumbnail" [src]="account?.Thumbnail" />
        }
        @if (fileUploading) {
          <div class="user-thumbnail">
            <ui-loader></ui-loader>
          </div>
        }
        <div class="edit-btn" (click)="openDetails('user-info')">{{ 'shared.actions.edit' | translate }}</div>
      </div>
      <div class="username">{{ account.Username }}</div>
      <div class="email">
        @if (account?.IdentityProvider?.includes('google')) {
          <span class="icon icon-google"></span>
        }
        @if (account?.IdentityProvider?.includes('facebook')) {
          <span class="icon icon-facebook2"></span>
        }
        @if (account?.IdentityProvider?.includes('apple')) {
          <span class="icon icon-apple"></span>
        }
        {{ email$ | async }}
      </div>
    </section>
    <section class="actions-menu">
      <div class="action-item" (click)="openDetails('user-info')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'accountPanel.actions.manage' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right"></div>
        </div>
      </div>
      <div class="action-item disabled" (click)="openDetails('notifications')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'accountPanel.actions.notifications' | translate }}
          @if (unviewedNotificationsNumber$ | async) {
            <div class="notification-dot"></div>
          }
        </div>
        <div class="right">
          <div class="icon icon-arrow-right"></div>
        </div>
      </div>
      <div class="action-item" (click)="openDetails('purchaseHistory')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'accountPanel.actions.purchaseHistory' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right-body"></div>
        </div>
      </div>
      <div class="action-item" (click)="openDetails('subscriptionManagement')">
        <div class="left">
          @if (!account) {
            <div class="icon icon-lock"></div>
          }
          {{ 'accountPanel.actions.subscriptionManagement' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right-body"></div>
        </div>
      </div>
      <div class="action-item disabled">
        <div class="left">
          <div class="icon icon-lock"></div>
          {{ 'accountPanel.actions.graphicsSettings' | translate }}
        </div>
        <div class="right">
          <div class="icon icon-arrow-right"></div>
        </div>
      </div>
      <div class="action-item" (click)="openDetails('language')">
        <div class="left">{{ 'accountPanel.actions.language' | translate }}</div>
        <div class="right">
          {{ ((currentLang$ | async) === languageCode.English ? 'shared.fields.language.en' : 'shared.fields.language.ko') | translate }}
        </div>
      </div>
    </section>
    <section class="footer">
      <div class="logout">
        <button ui-outlined-button tabindex="-1" xs (click)="onLogout()">{{ 'accountPanel.footer.logout' | translate }}</button>
      </div>
      @if (latestReleaseVersion$ | async; as version) {
        <div class="release-log">
          <span tabindex="-1" (click)="openDetails('release-log')"
            >{{ 'accountPanel.footer.releaseLog' | translate }} <span class="version">{{ version }}</span></span
          >
        </div>
      }
      <div class="legal-info">
        <div class="terms" (click)="openDetails('terms-of-use')">{{ 'accountPanel.footer.terms' | translate }}</div>
        <div class="privacy-policy" (click)="openDetails('privacy-policy')">{{ 'accountPanel.footer.privacyPolicy' | translate }}</div>
      </div>
    </section>
  </div>
</ng-template>
@if ((openedDetails$ | async) === 'terms-of-use') {
  <m-account-panel-terms-of-use></m-account-panel-terms-of-use>
}
@if ((openedDetails$ | async) === 'privacy-policy') {
  <m-account-panel-privacy-policy></m-account-panel-privacy-policy>
}
@if ((openedDetails$ | async) === 'user-info') {
  <m-account-panel-user-info></m-account-panel-user-info>
}
@if ((openedDetails$ | async) === 'security') {
  <m-account-panel-security></m-account-panel-security>
}
@if ((openedDetails$ | async) === 'withdrawal') {
  <m-account-panel-withdrawal></m-account-panel-withdrawal>
}
@if ((openedDetails$ | async) === 'language') {
  <m-account-panel-language></m-account-panel-language>
}
@if ((openedDetails$ | async) === 'release-log') {
  <m-account-panel-release-log></m-account-panel-release-log>
}
@if ((openedDetails$ | async) === 'subscriptionManagement') {
  <m-account-panel-subscription-management></m-account-panel-subscription-management>
}
@if ((openedDetails$ | async) === 'purchaseHistory') {
  <m-account-panel-information
    [description]="
      'accountPanel.details.information.goToPc' | translate: { openedDetailName: 'accountPanel.actions.purchaseHistory' | translate }
    "
  ></m-account-panel-information>
}
