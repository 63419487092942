import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewRoutingModule } from './view-routing.module';
import { ViewComponent } from './view.component';
import { CanvasComponent } from '../canvas/canvas.component';
import { MobileComponentsModule } from '@/mobile/src/app/components/m-components.module';
import { UiModule } from '@/ui/src/public-api';

@NgModule({
  imports: [CommonModule, ViewRoutingModule, MobileComponentsModule, UiModule],
  declarations: [ViewComponent, CanvasComponent],
  exports: [ViewComponent, CanvasComponent],
})
export class ViewModule {}
