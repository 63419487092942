import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { environment } from '@/mobile/src/environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountService } from '@/data/src/lib/services/account.service';
import { DOCUMENT } from '@angular/common';

const PROVIDER_KEYWORDS = ['google', 'facebook', 'apple'];
@UntilDestroy()
@Component({
  selector: 'm-account-panel-security',
  templateUrl: './m-account-panel-security.component.html',
  styleUrls: ['./m-account-panel-security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAccountPanelSecurityComponent implements OnInit {
  isSso = false;

  constructor(
    private _accountPanelService: AccountPanelService,
    private _translateService: TranslateService,
    private _accountService: AccountService,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit(): void {
    this._accountPanelService.account$.pipe(untilDestroyed(this)).subscribe((account) => {
      if (account?.IdentityProvider) {
        this.isSso = account?.IdentityProvider ? PROVIDER_KEYWORDS.some((e) => account.IdentityProvider.includes(e)) : false;
      } else {
        this.isSso = false;
      }
    });
  }

  onClose() {
    this._accountPanelService.closePanel();
  }

  onBack() {
    this._accountPanelService.closeDetails();
  }

  async changePassword() {
    const email = await this._accountService.getEmail();
    let redirectUrl = environment.redirectURL.replace(':', '%3A');
    redirectUrl = redirectUrl.replaceAll('/', '%2F');

    this._document.location.href = `https://uroomauth.b2clogin.com/uroomauth.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Change_Password&client_id=fd48c316-b774-4e3f-bd18-b55871c29fc3&nonce=defaultNonce&redirect_uri=${redirectUrl}&scope=openid&response_type=id_token&prompt=login&ui_locales=${this._translateService.currentLang}&email-hint=${email}`;
  }

  navigateToWithdrawal() {
    this._accountPanelService.openDetails('withdrawal');
  }
}
