<div class="frame">
  <section class="header">
    <div class="icon-wrapper back" (click)="onBack()">
      <div class="icon icon-back xs"></div>
    </div>
    {{ 'accountPanel.actions.releaseLog' | translate }}
    <div class="icon-wrapper close" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="body">
    @if (releaseLog$ | async; as releaseLog) {
      <main>
        @for (logItem of releaseLog; track logItem; let i = $index) {
          <section class="log-item">
            <div class="title" (click)="openVersion(i)">
              <div class="version">{{ logItem.VersionNumber }}</div>
              <div class="icon icon-arrow-right" [class.opened]="openedVersion === i"></div>
              <div class="time">{{ logItem.DisplayDate | dateAsAgo }}</div>
            </div>
            <div
              class="body"
              [class.opened]="openedVersion === i"
              [style.max-height]="openedVersion === i ? list.offsetHeight + 'px' : '0px'"
            >
              <ul #list>
                @for (item of logItem.Data; track item) {
                  <li>{{ item.Title[currentLanguage] | translate }}</li>
                }
              </ul>
            </div>
          </section>
        }
      </main>
    }
  </section>
</div>
