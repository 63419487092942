import { LanguageCode } from '@/data/src/lib/services/account.service';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'm-account-panel-language',
  templateUrl: './m-account-panel-language.component.html',
  styleUrls: ['./m-account-panel-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAccountPanelLanguageComponent implements OnInit {
  languageCode = LanguageCode;
  selectedLang: LanguageCode;
  loading = false;

  constructor(private _accountPanelService: AccountPanelService) {
    this._accountPanelService.currentLang$.pipe(untilDestroyed(this)).subscribe((lang: LanguageCode) => {
      this.selectedLang = lang;
    });
  }

  ngOnInit(): void {}

  onClose() {
    this._accountPanelService.closePanel();
  }

  onBack() {
    this._accountPanelService.closeDetails();
  }

  async onChangeLanguage() {
    this.loading = true;
    await this._accountPanelService.saveLanguage(this.selectedLang);
    this.loading = false;
    this._accountPanelService.closeDetails();
  }
}
