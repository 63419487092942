<div class="frame">
  <section class="header">
    {{ 'subscription.header' | translate }}
    <div class="icon-wrapper" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="body">
    <ui-m-subscription-plan></ui-m-subscription-plan>
  </section>
</div>
