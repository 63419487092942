import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@/data/src/lib/services/account.service';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { interval, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';
@UntilDestroy()
@Component({
  selector: 'm-account-panel-privacy-policy',
  templateUrl: './m-account-panel-privacy-policy.component.html',
  styleUrls: ['./m-account-panel-privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAccountPanelPrivacyPolicyComponent implements OnInit {
  currentLanguage$ = this._accountService.language$;

  isScrolling = false;

  @HostListener('scroll', ['$event.target']) onHostScroll() {
    if (!this.isScrolling) {
      this.isScrolling = true;

      this._ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this.isScrolling = false;
            this._cd.detectChanges();
            this._ngZone.run(() => {});
          });
      });
    }
  }

  constructor(
    private _accountPanelService: AccountPanelService,
    private _cd: ChangeDetectorRef,
    private _accountService: AccountService,
    private _router: Router,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit(): void {}

  onClickLink(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (target.tagName === 'LI') {
      const scrollTarget = this._document.getElementsByClassName(target.className)[1];
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }

  openDetails(name: string): void {
    this._accountPanelService.openDetails(name);
  }

  closeDetails(): void {
    this._document.location.href.includes('privacyPolicy')
      ? this._router.navigate(['community'])
      : this._accountPanelService.closeDetails();
  }
}
