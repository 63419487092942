<div class="frame">
  <section class="header">
    <div class="icon-wrapper back" (click)="onBack()">
      <div class="icon icon-back xs"></div>
    </div>
    {{ 'accountPanel.actions.securitySettings' | translate }}
    <div class="icon-wrapper close" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="body">
    <main>
      @if (!isSso) {
        <button ui-button (click)="changePassword()">
          {{ 'accountPanel.details.securitySettings.changePassword' | translate }}
        </button>
      }
      <button ui-outlined-button class="outline" (click)="navigateToWithdrawal()">
        {{ 'accountPanel.details.securitySettings.withdrawal.title' | translate }}
      </button>
    </main>
  </section>
</div>
