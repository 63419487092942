import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  declarations: [],
  exports: [BrowserModule, BrowserAnimationsModule, CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule],
})
export class MobileSharedModule {}
