<div class="frame">
  <section class="header">
    <div class="icon-wrapper back" (click)="onBack()">
      <div class="icon icon-back xs"></div>
    </div>
    {{ 'accountPanel.actions.language' | translate }}
    <div class="icon-wrapper close" (click)="onClose()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="body">
    <main>
      <button
        ui-button
        secondary
        class="language-button ko"
        [class.active]="selectedLang === languageCode.Korean"
        (click)="selectedLang = languageCode.Korean"
      >
        {{ 'shared.fields.language.ko' | translate | uppercase }}
      </button>
      <button
        ui-button
        secondary
        class="language-button en"
        [class.active]="selectedLang === languageCode.English"
        (click)="selectedLang = languageCode.English"
      >
        {{ 'shared.fields.language.en' | translate | uppercase }}
      </button>
    </main>
  </section>
  <section class="footer">
    <button ui-button (click)="onChangeLanguage()">
      @if (loading) {
        <ui-loader sm [loaderColor]="'white'"></ui-loader>
      }
      @if (!loading) {
        <span>{{ 'shared.actions.change' | translate }}</span>
      }
    </button>
  </section>
</div>
