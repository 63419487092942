<div class="frame">
  <section class="header">
    {{ 'accountPanel.footer.privacyPolicy' | translate }}
    <div class="icon-wrapper" (click)="closeDetails()">
      <div class="icon icon-close xs"></div>
    </div>
  </section>
  <section class="body">
    @if ((currentLanguage$ | async) === 'ko') {
      <div class="container">
        <div class="block"></div>
        <div class="contents">
          <article>
            <p>
              주식회사 <strong>유룸</strong>(이하 "회사")은 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를
              준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게
              개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
              개인정보 처리방침을 수립·공개합니다.
            </p>
            <br />
            <p>
              회사에 가입 신청을 하거나 회사에서 웹 기반 메타버스 제작 플랫폼 “OwnXR”을 통해 제공하는 제반 서비스(이하 “OwnXR 서비스”,
              “OwnXR” 또는 “서비스”) 이용 등을 하는 것은 OwnXR 이용약관 및 본 개인정보 처리방침에 동의함을 뜻합니다. 본 개인정보 처리방침은
              사이트 내 지정된 공간에 항상 게시되며, 개정될 경우 바뀐 내용과 이유를 이용자들이 알 수 있도록 사이트 상에 공지합니다. 본
              개인정보 처리방침에서 사용되는 용어는 OwnXR 서비스 이용약관에서의 용어와 동일합니다.
            </p>
          </article>
          <article>
            <div class="sub-title">
              <h2>1. 개인정보의 수집,이용 목적</h2>
              <p>
                회사는 이용자의 신분과 서비스 이용 의사를 확인하여 최적화되고 맞춤화 된 서비스를 제공하기 위해 아래와 같은 목적으로
                개인정보를 수집합니다. 수집한 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는
                「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 단, 회사가 제공하는 서비스에
                링크되어 있는 외부 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 개인정보 처리방침이 적용되지 않음을 알려드립니다.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">1.1. 제품 문의</p>
              <p>
                제품 문의와 관련한 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락•통지, 처리결과 통보 등을 목적으로 개인정보를
                처리합니다.
              </p>
              <p class="mini-title">1.2. 서비스 제공</p>
              <p>
                신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 서비스 이용에
                대한 통계 등 회사의 서비스를 제공하기 위한 목적으로 개인정보를 처리합니다.
              </p>
              <p class="mini-title">1.3. 마케팅 및 광고 활용  </p>
              <p>정보주체의 선택에 따라 이벤트 및 광고성 정보 제공 및 참여기회 제공을 목적으로 개인정보를 처리합니다.  </p>
              <p class="mini-title">1.4. 회원가입 및 관리 </p>
              <p>
                회원제 서비스 제공에 따른 개인 식별•인증, 서비스 가입 의사 확인, 서비스 제공, 이용약관 위반 회원에 대한 이용 제한 조치, 가입
                및 가입 횟수 제한, 서비스 부정 이용 제재, 비인가 사용 방지, 고충 처리 및 분쟁 조정을 위한 기록 보존, 고지사항 전달, 회원
                탈퇴 의사 확인을 목적으로 개인정보를 처리합니다.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>2. 개인정보 수집 및 이용 정책 </h2>
              <p>회사는 서비스 제공을 위해 하기와 같이 이용자의 개인정보를 수집•이용 합니다. </p>
            </div>
            <div class="content">
              <p class="mini-title">2.1. 개인정보 수집방법 </p>
              <p>
                회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br />2.1.1. 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트
                응모 <br />2.1.2. 협력회사로부터의 제공 <br />2.1.3. 생성정보 수집 툴을 통한 수집
              </p>
              <p class="mini-title">2.2. 처리하는 개인정보 항목  </p>
              <p>
                회사의 홈페이지 또는 OwnXR 홈페이지(www.ownxr.com)에서 수집하는 개인정보는 다음과 같으며, 이는 본 개인정보처리방침의 "1.
                개인정보의 수집∙이용 목적"에 명시된 목적으로 이용됩니다.
                <br />2.2.1. 서비스 제공: 이메일, 비밀번호
                <br />
                2.2.2. 서비스 제공 시 기타수집 항목: 기기정보(하드웨어 모델, 운영체제 버전, 고유기기 식별정보) 쿠키정보, IP정보, 접속 로그,
                서비스 이용기록, 방문기록
                <br />* 서비스 이용과정에서 기기정보, 접속로그, 이용기록 정보가 자동으로 생성되어 수집될 수 있습니다. <br />2.2.3. 제품 문의
                시 수집 항목: 성명, 소속 기관, 전화번호, 이메일
              </p>
              <p class="mini-title">2.3. 개인정보 이용 정책</p>
              <p>
                2.3.1. 소유권 변경: 회사가 자산 파산, 합병, 인수, 개편 또는 판매에 관여하는 경우 이용자의 개인정보는 해당 거래의 일부로 판매
                또는 양도될 수 있습니다. 이 개인정보 보호 정책은 새로운 법인에게 양도된 개인정보에 적용됩니다.
                <br />
                2.3.2. "사이트" 이용자의 정보 접근 또는 수정 OwnXR "사이트" 이용을 위해 계정 생성했을 경우 회사는 모든 이용자에게 그들이
                계정 생성 시 제공한 개인정보에 접근 및 수정 기능을 제공합니다. 계정 삭제를 원하는 경우 "사이트"에 로그인 후 지침에 따라 계정
                삭제를 요청할 수 있습니다. 세금 또는 회계법 준수와 같은 개인정보를 저장할 법적 근거가 없는 한, 회사는 "사이트" 이용자가 계정
                삭제를 요청할 경우 절차에 따라 개인정보를 삭제하거나 식별 해제합니다.
                <br />
                2.3.3. 동의 철회 모바일 또는 PC 웹 브라우저에서 사용 중인 웹 브라우저의 안내에 따라 쿠키를 작동 가능, 불가능 또는 삭제가
                가능합니다. 쿠키 사용을 거부 또는 삭제하였을 경우 서비스 이용에 제한이 있을 수 있습니다. 그 이후 해당 디바이스에서 더 이상
                맞춤형 광고 경험을 제공하기 위한 개인정보를 수집, 사용, 공유 또는 처리하지 않습니다. 회사가 이용자의 개인정보를 저장하기
                위한 또 다른 법적 근거가 없는 한, 이용자가 동의를 철회하기 위해 이러한 방법 중 하나를 사용하면 30 일 이내에 수집된 이용자의
                개인정보를 삭제하거나 식별을 해제합니다.
                <br />
                2.3.4. 법적 필요성 법에 따라 필요한 개인정보를 처리합니다. 예를 들어, 회사는 이용자가 유럽 경제 지역, 영국 또는 스위스에
                있는지 확인하기 위해 이용자의 IP 주소를 수집할 수 있지만 전체 IP 주소를 공유하거나 내부적으로 유지하지 않습니다.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>3. 개인정보의 제3자 제공 또는 처리위탁 </h2>
              <p>
                회사는 이용자들의 개인정보를 "2. 개인정보 수집 및 이용 정책"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동
                범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제3자 제공하거나 처리위탁하지 않습니다.
                <br />다만, 아래의 경우에는 예외로 합니다.<br />- 이용자들이 사전에 동의한 경우<br />- 법령의 규정에 의거하거나, 수사
                목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우 <br />- 회사는 원활한 개인정보 업무처리를 위하여
                다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
              </p>
            </div>
            <table>
              <thead>
                <tr>
                  <td class="column-1">위탁받는 자</td>
                  <td class="column-2">위탁업무</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Microsoft Azure</td>
                  <td>서버 호스팅</td>
                </tr>
                <tr>
                  <td>SendGrid</td>
                  <td>이메일 발송 </td>
                </tr>
                <tr>
                  <td>Google Analytics</td>
                  <td>웹사이트 트래픽 분석</td>
                </tr>
                <tr>
                  <td>Stripe</td>
                  <td>결제 대행 (Payment Processing) </td>
                </tr>
              </tbody>
            </table>
          </article>
          <article>
            <div class="sub-title">
              <h2>4. 개인정보 보유 및 이용기간 </h2>
              <p>
                회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
                개인정보를 처리·보유하며 특히 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존하고 그 외 다른 목적으로는 절대
                사용하지 않습니다.
                <br />[관련법령에 의한 정보보유 사유]
                <br />
                상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서
                정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와
                같습니다.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">4.1. 회원 가입 및 관리: 이용자 OwnXR 탈퇴 시까지 </p>
              <p>
                다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지<br />1) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는
                해당 수사·조사 종료 시까지 <br />2) 서비스 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지
              </p>
              <p class="mini-title">4.2. 서비스 제공: 서비스 공급완료 및 요금결제·정산 완료시까지 </p>
              <p>
                다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지<br />1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른
                표시·광고, 계약내용 및 이행 등 거래에 관한 기록 <br />- 표시·광고에 관한 기록 : 6개월 <br />- 계약 또는 청약철회, 대금결제,
                재화 등의 공급기록 : 5년 <br />- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 <br />2) 「신용정보의 이용 및 보호에 관한
                법률」에 따른 신용정보의 수집/처리 및 이용 등에 관한 기록: 3년
              </p>
              <p class="mini-title">4.3. 제품 문의 </p>
              <p>민원처리 완료 후 2년까지 </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>5. 개인정보 파기절차 및 방법 </h2>
              <p>
                개인정보를 파기합니다. 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 기타 관련
                법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보 (종이의 경우, 별도의 전자 파일로 변환)를 별도의 데이터
                베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 이때, 별도의 DB로 옮겨진 개인정보는 법률에 의한 경우 외에는 다른
                목적으로 이용되지 않습니다.
                <br />파기절차 및 파기방법은 다음과 같습니다.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">5.1. 파기절차 </p>
              <p>회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. </p>
              <p class="mini-title">5.2. 파기방법 </p>
              <p>
                (1) 전자적 파일 형태로 기록·저장된 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 파기합니다.<br />(2) 종이에
                기록·저장된 정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
              </p>
              <p class="mini-title">5.3. 서비스 장기 미이용 처리 정책 </p>
              <p>
                이용자의 개인정보 보호를 위하여 일정 기간 서비스를 이용하지 않으면 아래와 같이 개인정보를 파기 또는 분리 보관 후 이용계약을
                해지할 수 있습니다.
                <br />1) 1년 동안 어떠한 서비스도 이용하지 않은 경우 또는 계정이 휴면 전환되어 개인정보를 분리 보관합니다.<br />2) 분리 보관
                이후 4년 동안 로그인 하지 않을 경우, 개인정보를 파기하고 계정은 탈퇴 처리합니다.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>6. 이용자의 권리,의무 및 행사방법 </h2>
            </div>
            <div class="content">
              <p class="mini-title">
                6.1 이용자는 언제든지 등록되어 있는 자신의 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
              </p>
              <p class="mini-title">
                6.2 이용자의 개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를 위해서는
                “회원탈퇴” 절차를 통해 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호책임자에게 서면
                또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
              </p>
              <p class="mini-title">
                6.3 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
                않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이
                이루어지도록 하겠습니다.
              </p>
              <p class="mini-title">
                6.4 회사는 이용자 요청에 의해 해지 또는 삭제된 개인정보는 “4. 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그
                외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
              </p>
              <p class="mini-title">
                6.5 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에
                관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              </p>
              <p class="mini-title">
                6.6 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수
                있습니다.
              </p>
              <p class="mini-title">
                6.7 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
                없습니다.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>7. 개인정보의 안정성 확보 조치</h2>
              <p>회사는 개인정보 보호법 제29조에 따라 이용자들의 개인정보 보호를 위해 다음과 같은 기술적∙관리적 노력을 하고 있습니다.</p>
            </div>
            <div class="content">
              <p class="mini-title">7.1. 개인정보 암호화 </p>
              <p>
                이용자의 비밀번호 등 중요 정보는 암호화되어 저장, 관리되고 있으며, 개인정보의 확인 및 변경은 본인에 의해서만 가능합니다.
              </p>
              <p class="mini-title">7.2. 해킹 등에 대비한 대책 </p>
              <p>
                회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
                개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나
                손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
                그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한
                모든 기술적 장치를 갖추려 노력하고 있습니다.
              </p>
              <p class="mini-title">7.3. 개인정보 취급 직원의 최소화 및 교육 </p>
              <p>
                회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며,
                담당자에 대한 수시 교육을 통하여 회사 개인정보처리방침의 준수를 항상 강조하고 있습니다.
              </p>
              <p class="mini-title">7.4. 개인정보 보호 전담조직의 운영 </p>
              <p>
                개인정보 보호를 위해 개인정보 보호전담부서를 운영하고 있으며, 개인정보 처리방침의 이행사항 및 개인정보 취급자의 준수 여부를
                확인하여 문제가 발견될 경우 즉시 수정하고 바로잡을 수 있도록 노력하고 있습니다.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>8. 개인정보 관리 책임자 및 담당 부서 </h2>
              <p>
                회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 보호책임자를 지정하고 있고, 연락처는 아래와 같습니다.
                이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 문의, 불만처리, 피해구제에 관한 민원을 개인정보
                보호책임자 혹은 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">8.1. 개인정보 보호책임자</p>
              <p>성명 : 박재홍<br />소속/직책 : 경영팀, 대표<br />연락처 : support&#64;uroom.io</p>
            </div>
          </article>
          <article>
            <h2>9. 이용자의 권익침해 구제방법</h2>
            <div class="content">
              <p>
                이용자는 개인정보 침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원<br />
                개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해에 대한 신고나 상담이 필요하신
                경우에는 아래 기관에 문의하시기 바랍니다.
                <br />- 한국인터넷진흥원 개인정보침해신고센터 (https://privacy.kisa.or.kr/main.do, 국번없이 118) <br />- 대검찰청
                (http://www.spo.go.kr, 국번없이 1301) <br />- 경찰청 사이버범죄 신고시스템(https://ecrm.police.go.kr/minwon/main, 국번없이
                182)
              </p>
            </div>
          </article>
          <article>
            <h2>10. 개인정보 처리방침의 변경 </h2>
            <div class="content">
              <p class="mini-title">
                10.1 회사는 개인정보 처리방침에 대한 변경이 있을 경우에는 개정 개인정보 처리방침의 시행일로부터 최소 7일 전에 홈페이지의
                공지사항 또는 이메일을 통해 고지하며, 이 정책은 고지한 날로부터 시행됩니다. 다만, 개인정보의 수집·이용 목적, 제3자 제공 대상
                등 중요한 사항이 추가 및 삭제, 수정되는 경우에는 30일 전에 사전 공지하고, 30일이 경과된 후에 시행됩니다.
              </p>
              <p class="mini-title">
                10.2  당사는 개인정보의 수집 및 활용, 제3자 제공 등 정보통신망 이용 촉진 및 정보 보호 등에 관한 법률 등 관련 법령에 따라
                고객의 별도 동의가 필요한 사항과 관련된 내용이 추가, 변경되는 경우에는 관련 법령에 따른 고객의 별도 동의를 받습니다.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>11. GDPR 컴플라이언스 </h2>
              <p>
                회사는 유럽연합(EU) 개인정보보호법(GDPR)을 준수하기 위해 다음과 같은 노력을 합니다.<br />- GDPR 인식 제고 활동<br />-
                개인정보 영향평가 수행<br />- 이용자 권리 보장<br />- 개인정보 유출 신고 및 통지
              </p>
            </div>
            <div class="content">
              <p class="mini-title">11.1. GDPR 인식 제고 활동 </p>
              <p>
                회사는 GDPR을 준수하기 위해 전사적인 노력과 관심을 쏟고 있습니다. GDPR이 우리 조직에 끼칠 영향을 확인하고, 다음과 같은
                활동을 통해 직무별로 GDPR을 준수할 수 있도록 최선을 다하고 있습니다.
                <br />- 참여 부서: 고객관리 인사, 재무, 마케팅, 시스템 개발 등 <br />- 개인정보보호 지식 수준에 대한 조직원 대상의 설문조사
                <br />- 경영진의 GDPR 준수 의지의 공식적 선언 <br />- GDPR 컨퍼런스, 세미나 참여 독려 <br />- 부서별 수행 항목 체크리스트
                관리
              </p>
              <p class="mini-title">11.2. 개인정보 영향평가 수행</p>
              <p>
                회사는 GDPR제35조에 따라 자연인의 권리 및 자유에 대해 고위험을 초래할 가능성이 있는 다음에 해당하는 경우 개인정보 영향평가를
                수행합니다.
                <br />
                - 프로파일링 등의 자동화된 처리에 근거한, 개인에 관한 개인적 측면을 체계적이고 광범위하게 평가하는 것으로 해당 평가에 근거한
                결정이 해당 개인에게 법적 효력을 미치거나 이와 유사하게 개인에게 중대한 영향을 미치는 경우
                <br />- 공개적으로 접근 가능한 지역에 대한 대규모의 체계적 모니터링<br />
                - 제9조(1)에 규정된 특정 범주의 개인정보(민감정보)에 대한 대규모 처리나 제10조에 규정된 범죄경력 및 범죄 행위에 관련된
                개인정보에 대한 처리
              </p>
              <P class="mini-title">11.3. 이용자 권리 보장</P>
              <p>
                회사는 GDPR에서 규정하는 다음의 이용자 권리 보장을 위해 노력합니다.<br />- 삭제권(‘잊힐 권리’)<br />- 개인정보 이동권<br />
                <br />
                11.3.1 삭제권
                <br />11.3.1.1 삭제권<br />정보주체는 GDPR 제17조에 따라 본인과 관련된 개인정보를 삭제하도록 요구할 수 있는 권리(삭제권)를
                가집니다.<br />- 개인정보가 본 개인정보 처리방침 제1조에 해당하는 수집 또는 처리 목적과 관련하여 더 이상 필요하지 않은 경우
                <br />
                - 정보주체가 개인정보 처리에 대한 동의를 철회하였으며, 해당 개인정보를 처리할 법적 근거가 없는 경우 (단, 이 경우 OwnXR
                서비스는 더 이상 이용할 수 없음)
                <br />
                - 정보주체가 GDPR 제21조(반대권) 제1항에 따라 개인정보 처리에 반대하고, 관련 개인정보 처리에 우선하는 정당한 사유가 없는
                경우 또는 GDPR 제21조 제2항에 의한 직접 마케팅에 정보주체가 반대하는 경우 (단, 제21조 제1항의 경우 OwnXR 서비스는 더 이상
                이용할 수 없음)
                <br />- 개인정보가 불법적으로 처리된 경우<br />- EU 또는 EU 회원국 법령의 준수를 위해 개인정보의 삭제가 필요한 경우 <br />-
                아동을 대상으로 한 정보사회서비스의 제공과 관련하여 개인정보가 수집된 경우
                <br />
                <br />
                11.3.1.2. 삭제권의 제한<br />단, 회사는 GDPR제17조 제3항에 따라 다음 중 하나에 해당될 경우에는 삭제 요구를 거부할 수
                있습니다. <br />- 표현 및 정보의 자유에 관한 권리 행사를 위한 경우
                <br />
                - EU 또는 EU 회원국의 법적 의무를 준수하거나, 공익상의 업무를 수행하기 위해 또는 회사에게 부여된 공적 권한을 행사하기 위한
                경우
                <br />- 공익을 위한 보건 목적을 위한 경우 <br />- 공익적 기록보존 및 과학적 또는 역사적 연구, 통계적 목적을 위한 경우
                <br />- 법적 청구권의 입증이나 행사 또는 방어를 위한 경우
                <br />
                <br />11.3.2. 개인정보 이동권
                <br />
                회사는 GDPR 제20조에 따라 정보주체에게 수집한 개인정보를 “체계적으로 구성되고 일반적으로 널리 이용되며 기계에 의해 판독이
                가능한 형식”으로 제공하며, 정보주체는 회사에게 제공한 개인정보를 직접 수령할 수도 있고, 기술적으로 가능한 경우에는 다른
                개인정보처리자로 직접 제공하도록 신청할 수도 있습니다.
              </p>
              <p class="mini-title">11.4. 개인정보 유출 신고 및 통지 </p>
              <p>
                회사는 개인의 권리와 자유에 위험을 야기할 가능성이 있는 다음과 같은 침해가 발생할 경우 유출 사실을 알게 된 때로부터 72시간
                내에 감독 당국에 신고합니다.
              </p>
              <p>- 차별행위</p>
              <p>- 평판훼손</p>
              <p>- 재정적 손실</p>
              <p>- 비밀 누설</p>
              <p>- 기타 중대한 경제적, 사회적 불이익 위험</p>
              <p>회사는 정보주체의 자유와 권리에 고위험이 예상될 때에는 부당한 지체 없이 유출 사실을 정보주체에게 통지합니다.</p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>12. CPRA 컴플라이언스 </h2>
              <p>
                이용자가 미국 캘리포니아 거주자인 경우, 캘리포니아 개인정보 권리법 (California Privacy Rights Act, "CPRA")에 따라 본 조가
                적용될 수 있습니다.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">12.1. 수집될 수 있는 소비자 정보 </p>
              <p>
                회사는 캘리포니아의 거주자, 기기 또는 가구를 식별, 설명, 참조하거나 직접 또는 간접적으로 연관되거나, 합리적으로 연관될 수
                있는 정보(“소비자 정보")와 본 개인정보 처리방침에 기재한 정보를 수집할 수 있습니다.
              </p>
              <p class="mini-title">12.2. 소비자 정보의 사용 </p>
              <p>
                회사는 개인정보와 관련하여 본 개인정보 처리방침에 규정된 방법으로 사업적 또는 상업적 목적으로 소비자 정보를 사용할 수
                있습니다.
              </p>
              <p class="mini-title">12.3 비즈니스 목적을 위한 소비자 정보 공개 </p>
              <p>
                회사는 CPRA에 따라 다음 범주의 소비자 정보에 대한 개인정보와 관련하여 사업 목적을 위해 위에 설명된 귀하의 소비자 정보를 제3
                자에게 공개할 수 있습니다.
                <br />- 식별자 : 제13조 (v) 항 (A)호에 따른 성명, 별명, 개인 고유 식별자, 온라인 식별자, IP 주소, 이메일, 계정 ID 등 <br />-
                캘리포니아 고객 기록 법 (Cal. Civ. Code § 1798.80 (e))에 나열된 개인정보 범주 <br />- 인터넷 또는 기타 유사한 네트워크의
                활동
              </p>
              <p class="mini-title">12.4. 소비자 정보의 판매</p>
              <p>회사는 본 개인정보 처리방침이 적용되는 동안 소비자 정보를 판매하지 않으며 앞으로도 판매하지 않을 것입니다.</p>
              <p class="mini-title">12.5. 캘리포니아 거주자의 권리 및 선택</p>
              <p>
                CPRA는 캘리포니아 주민들에게 소비자 정보에 관한 특정 권리를 제공합니다. 12.5조는 귀하의 CPRA 권리 (적용 가능한 범위까지)를
                설명하고 이러한 권리를 행사하는 방법을 설명합니다.
                <br />12.5.1. 특정 정보의 접근 요청 및 이동에 대한 권리<br />
                이용자는 회사가 지난 12 개월 동안 이용자 본인의 소비자 정보 수집 및 사용에 대한 특정 정보를 제공하도록 요청할 권리가
                있습니다. 확인 가능한 소비자 요청 (아래 12.6조에 설명된 방식)을 수신하고 확인하면 CPRA에서 요구하는 한도 내에서 공개합니다.
                <br />12.5.2. 정보 삭제, 정정요청에 대한 권리<br />
                이용자는 회사가 이용자로부터 수집하고 보유한 이용자 본인의 소비자 정보를 삭제, 정정하도록 요청할 권리가 있습니다. 아래
                12.6조에 설명된 방식으로 확인 가능한 요청을 수신하고 확인하면, 회사는 이용자의 소비자 정보를 삭제, 정정합니다. 단, 회사 또는
                유관 서비스 제공 업체의 합법적인 사업 목적을 위하여 정보를 보유해야 하는 경우, 회사는 해당 삭제 요청을 거부할 수 있습니다.
              </p>
              <p class="mini-title">12.6. 권리의 행사 </p>
              <p>
                12.6.1. 위의 12.5조에 설명된 정보의 접근, 이동 및 삭제, 정정 권한은 다음 중 한 가지 방법으로 행사할 수 있습니다. (1)
                개인정보 처리방침에 명시된 번호로 전화 (2) 웹 사이트 방문; 또는 당사에 연락. 이용자 또는 이용자를 대리하도록 권한을 부여한
                캘리포니아 주 국무 장관에 등록된 사람 만이 이용자 본인의 소비자 정보 및 유관 권리에 대한 요청을 대리할 수 있습니다.
                <br />12.6.2. 회사는 이용자의 요청을 접수한 후 45 일 이내에 응답하기 위해 노력하고, 추가적인 시간이 필요한 경우 (최대 90
                일), 이유와 연장 기간을 서면으로 알려드립니다.
              </p>
              <p class="mini-title">12.7 차별 금지 </p>
              <p>
                CPRA가 허용하는 범위 내에서, 이용자의 CPRA 권리 행사에 대하여 아래와 같은 차별을 행사하지 않겠습니다.<br />12.7.1.
                이용자에게 상품 또는 서비스 제공을 거부하는 행위
                <br />
                12.7.2. 할인 또는 기타 혜택을 부여하거나 벌금을 부과하는 것을 포함하여 상품 또는 서비스에 대해 서로 다른 가격 또는 요율을
                부과하는 행위
                <br />12.7.3. 다른 수준 또는 품질의 상품이나 서비스를 제공하는 행위<br />12.7.4. 상품 또는 서비스에 대하여 차별적인 가격,
                요율, 수준 및 품질을 제안하는 행위
              </p>
            </div>
          </article>
          <p>부칙: 이 개인정보 처리방침은 2023. 10. 13부터 적용됩니다. </p>
        </div>
      </div>
    } @else {
      <div class="container">
        <div class="block"></div>
        <div class="contents">
          <article>
            <p>
              URoom Inc. ("us", "we", "our", or “Company”) operates the OwnXR website (hereinafter referred to as the "Service(s)", “OwnXR”,
              or “OwnXR Service(s)”). This page informs you of our policies regarding the collection, use and disclosure of personal
              information when you use our Service and the choices you have associated with that information. We use your information to
              provide and improve the Service.
            </p>
            <br />
            <p>
              By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. Unless
              otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms of
              Service.
            </p>
          </article>
          <article>
            <div class="sub-title">
              <h2>1. PURPOSES OF COLLECTION AND USE OF PERSONAL INFORMATION</h2>
              <p>
                We may collect and process personal information about you for the following purposes. We do not otherwise share your
                personal information with third parties or use your personal information for any other purposes unless otherwise expressly
                provided herein. Please note, this Privacy Policy does not apply to any personal information collected by other websites
                that you may access through the links provided on our services.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">1.1 Response to User Request </p>
              <p>
                We may use your personal information in connection with responding to your inquiries and grievances. That is, we may process
                personal information about you to confirm your identity, to review your questions or complaints and to communicate to you
                with our responses.
              </p>
              <p class="mini-title">1.2 Provision of Goods or Services</p>
              <p>
                We may process your personal information to develop new services or provide more personalized and streamlined services based
                on demographic information or information about your interest and also to monitor statistics for your use of our services.
              </p>
              <p class="mini-title">1.3 Marketing or Promotion </p>
              <p>We may use or process personal information about you to invite you to our special promotional events from time to time.</p>
              <p class="mini-title">1.4 user Management  </p>
              <p>
                We may collect and use personal information about you as our user (as defined in our OwnXR Terms of Service) to verify your
                identity and status, to prevent unauthorized use or intrusion to our services, to confirm your consent to join our services,
                to verify registration status and prevent you from creating multiple accounts and also for our record keeping purposes in
                connection with dispute resolution and customer services.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>2. COLLECTION, USE AND DISCLOSURE OF PERSONAL AND NON-PERSONAL INFORMATION</h2>
              <p>
                We may collect personal information about you in connection with providing the OwnXR Services through the following methods.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">2.1. Collection Methods</p>
              <p>
                We may use the following methods to collect personal information about you<br />
                2.1.1 through our website, on paper forms, via facsimile and phone, or Q&A board on our website or by your entrance to our
                promotional events;
                <br />2.1.2 through our agents and third party partners; or<br />2.1.3 via information collecting tools.
              </p>
              <p class="mini-title">2.2. Collection Policy</p>
              <p>
                The following information about you may be collected on our website(www.ownxr.com) and we only use such information for the
                purposes set forth under “Section 1. Purposes of Collection and Use of Personal Information“
                <br />
                2.2.1. Information collected during User Management and Response to Service Inquiry: Name and email address, contact number,
                affiliated institution, country of residence, ID, Password
                <br />
                2.2.2. Information collected during provision of Service: Cookie, IP address, usage history, visiting history, and device
                information (model number, operating system and unique
                <br />identifiers of the device), medical images<br />* Device information, visiting history, usages history may be
                automatically collected during the use of Service. <br />2.2.3. Information collected during Service Inquiry: Name and email
                address, contact number, affiliated institution, ID
              </p>
              <p class="mini-title">2.3. Use Policy</p>
              <p>
                2.3.1. Change of Control Notice <br />
                In the event that we are involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your personal
                information may be sold or transferred as part of such transaction. This Privacy Policy will apply to your personal
                information as transferred to the new entity.
                <br />2.3.2. Your Right to Review and Update Information on the Website. <br />
                You may access and review your personal information that you provided when you created your user account on the Website so
                that you can update your information from time to time. If you wish to cancel and delete your account, you may login and
                follow the instructions contained on our Website. Unless we have a legal basis to store your personal information to comply
                with tax or accounting rules and regulations, we will delete or de-identify the personal information we have collected about
                you upon your request to delete or cancel your account.
                <br />2.3.3. Withdrawal of Consent<br />
                You may reject cookies or delete them at any time by change settings on your mobile or PC browser. However, you may not be
                able to use a certain portion of the OwnXR Services if you reject our use of cookies After the user enabled the respective
                settings on the device as provided above, we will no longer collect, use, share, or otherwise process personal information
                from that device for personalized advertising experience. Unless we have another legal basis for keeping the user’s personal
                information, we will also delete or de-identify the personal information we have collected about such user within 30 days
                from the date on which the user applied one of the above methods to withdraw consent.
                <br />2.3.4. Where Legally Required<br />
                We will process personal information as necessary to comply with applicable laws. For example, we will collect from the
                users of IP addresses to identify a country location so that we know whether such users are located in the European Economic
                Area, the United Kingdom, or Switzerland, but we will not share the users’ full IP addresses or retain the same internally.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>3. DISCLOSURE OR OURSOURCING THE PROCESSING OF PERSONAL INFORMATION</h2>
              <p>
                We use personal information about our users only to the extent provided under “Section 2. Collection and Use of Personal
                Information.” Except for the following circumstances, Company will not disclose, provide nor outsource the processing of
                personal information.
                <br />- when user consented in advance<br />
                - when the government agency requests in accordance with procedures and methods pursuant to the applicable law for the
                purpose of investigation.
                <br />
                We may share your personal information with our authorized service providers for outsourcing the processing of personal
                information as follows:
              </p>
            </div>
            <div class="content">
              <table>
                <thead>
                  <tr>
                    <td class="column-1">Serivce Provider</td>
                    <td class="column-2">Outsourced Work</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Microsoft Azure</td>
                    <td>Server Hosting</td>
                  </tr>
                  <tr>
                    <td>SendGrid</td>
                    <td>Email Delivery</td>
                  </tr>
                  <tr>
                    <td>Google Analytics</td>
                    <td>Tracking of Website Traffic</td>
                  </tr>
                  <tr>
                    <td>Stripe</td>
                    <td>Payment Processing</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>4. RETENTION AND USE OF PERSONAL INFORMATION</h2>
              <p>
                We will promptly delete or de-identify the personal information we have stored about our users once we have accomplished the
                purposes for collecting and storing the personal information as provided above with exception of the following reasons (and
                no other reasons) in which cases, we may store the personal information for the duration set forth below. Storing Personal
                Information Pursuant to Applicable Laws. <br />
                To the extent required under applicable laws, including, without limitation, the Commercial Act and the Act on the Consumer
                Protection in Electronic Commerce, etc., we may store personal information about you for certain period of time. We store
                such information only to be in compliance with applicable laws and only for the time period set forth below. In no case we
                use the information stored hereunder for any other purpose.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">4.1. User Sign In and Management: Until user's withdrawal, except the following cases:</p>
              <p>
                1) If an investigation is in progress due to violation of applicable laws, until the end of investigation.
                <br />2) If any claims remain in relation to the usage of Service, until the claims are settled.
              </p>
              <p class="mini-title">
                4.2. Provision of Service: Until the completion of provision of Service, or until the settlement of service fees, except the
                following cases:
              </p>
              <p>
                1) For the purpose of Act on the Consumer Protection in Electronic Commerce, Etc. <br />- Records on advertising and
                display: 6 months <br />- Records on entering into or withdrawing from contractual relationship; payment made; Service
                provided: 5 years, <br />- Records on Customer Complaints or Disputes: 3 years <br />2) For the purpose of Credit
                Information Use and Protection Act <br />- Records on Collection and/or Processing of Credit Information: 3 years
              </p>
              <p class="mini-title">4.3. Service Inquiries:2 years after responding the Service inquiries</p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>5. DESTRUCTION OF PERSONAL INFORMATION</h2>
              <p>
                We will promptly destroy personal information that is no longer required to fulfil the identified purposes stated above. We
                apply the following procedures in destroying such personal information.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">5.1. Destruction Procedures</p>
              <p>
                We will move such personal information that is no longer required to fulfil the identified purposes to a separate DB
                (separate files in case of information in hard copies) and maintain it for set duration of time or promptly destroy as
                required by applicable laws. We will not use the personal information that is maintained in separate DB for any reasons
                except to comply with applicable laws.
              </p>
              <p class="mini-title">5.2. Disposal Method</p>
              <p>
                - Personal information stored in electronic format will be deleted by using a technical approach to prevent it from being
                restored.
                <br />- Information on paper form will be shredded or incinerated.
              </p>
              <p class="mini-title">5.3. Long-Term Service Use Inactivity Policy</p>
              <p>
                Personal information may be destroyed or stored separately, as prescribed below, if the user does not use the Service for a
                specific amount of time, in order to protect the user’s personal information.
                <br />
                1) Unless a Member uses his/her account in any of Services for one (1) year, his/her account shall be inactive, and any
                information therein shall be stored separately.
                <br />
                2) Unless the aforementioned member does not log into account for four (4) years thereafter, his/her personal information
                shall be destroyed, and the relevant account shall be deleted.
              </p>
            </div>
          </article>
          <article>
            <h2>6. RIGHTS OF USERS</h2>
            <div class="content">
              <p class="mini-title">
                6.1 Users may at any time exercise their rights to access, correct, delete, or suspend processing of their registered
                personal information.
              </p>
              <p class="mini-title">
                6.2 To view and edit the user’s personal information, go through the identity verification process through ‘Change personal
                information’ (or ‘Modify user information’, etc.), and to withdraw membership (or withdraw your consent), go through the
                “Withdraw membership” procedure. You can view, edit, or withdraw your membership directly. Alternatively, if you may contact
                the Privacy Officer.
              </p>
              <p class="mini-title">
                6.3 If a user requests correction of an error in personal information, the personal information will not be used or provided
                until the correction is completed. Additionally, if incorrect personal information has already been provided to a third
                party, we will notify the third party of the result of the correction without delay so that the correction can be made.
              </p>
              <p class="mini-title">
                6.4 We will process personal information canceled or deleted at the user’s request in accordance with “Section 4. “Retention
                and Use of Personal information” and process it so that it cannot be viewed or used for any other purpose.
              </p>
              <p class="mini-title">
                6.5 Rights may be exercised through a representative, such as the user's legal representative or a person authorized to do
                so. In this case, you must submit a power of attorney in the required form.
              </p>
              <p class="mini-title">6.6 Requests to view and suspend personal information processing may limit your rights.</p>
              <p class="mini-title">
                6.7 Requests for correction or deletion of personal information cannot be requested if such personal information is
                collected pursuant to other laws and regulations.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>7. How We Protect Your Personal Information</h2>
              <p>We strive to safeguard personal information of our users by utilizing technology and management system as follows.</p>
            </div>
            <div class="content">
              <p class="mini-title">7.1. Encryption of Personal Information</p>
              <p>
                Sensitive and critical information such as your password is encrypted and only you can check and modify such information.
              </p>
              <p class="mini-title">7.2. Protection from Security Threats</p>
              <p>
                We are doing our best to safeguard personal information of our users from being leaked or damaged by hacking or computer
                viruses. In order to prevent the loss of personal information, we back up our data on a regular basis, use the latest
                anti-virus program, and use encryption technology to securely transmit personal information on our network. In addition, we
                use firewall to block unauthorized access from the outside, and we try to equip all possible technical devices to secure our
                system.
              </p>
              <p class="mini-title">7.3. Limiting Access to Your Personal Information</p>
              <p>
                To reduce the risk of security breach, we are limiting access to personal information of our users to only those employees
                who are specifically trained for handling of such information. We also change the password to the access periodically and
                place emphasis on the importance of safeguarding the personal information and compliance with applicable laws and policies
                by training our employees on a regular basis.
              </p>
              <p class="mini-title">7.4. Special Force Dedicated for Protection of Personal Information</p>
              <p>
                We have a special task force dedicated solely for protection of personal information of our users. We continuously monitor
                our compliance with applicable laws and regulations, and we strive to correct any breach or problem immediately upon notice.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>8. RESPONSIBLE PERSONNEL</h2>
              <p>
                We have designated the following person as Privacy Officer of personal information that we collect and process. You many
                direct your inquiries or complaints on our processing of your personal information to Privacy Officer. You may contact the
                below named Privacy Officer with all your inquiries on personal information. We will attempt to respond to your inquiries in
                a timely manner.
              </p>
            </div>
            <div class="content">
              <p class="mini-title">8.1. Privacy Officer</p>
              <p>- Name: Jaehong Park <br />- Title: Operation Department, Representative <br />- Contact: support&#64;uroom.io</p>
            </div>
          </article>
          <article>
            <h2>9. RELEVANT AGENCIES</h2>
            <div class="content">
              <p>
                You may also contact the following agencies to report any personal information breach or other related inquiries.
                <br />- Privacy Infringement Report Center (https://privacy.kisa.or.kr/main.do, or dial 118) <br />- Cyber Crime
                Investigation Unit, Supreme Prosecutor’s Office (http://www.spo.go.kr, or dial 1301) <br />- Cyber Terror Response Center,
                National Police Agency (https://ecrm.police.go.kr/minwon/main, or dial 182)
              </p>
            </div>
          </article>
          <article>
            <h2>10. NOTIFICATION OF CHANGES TO THIS PRIVACY POLICY</h2>
            <div class="content">
              <p class="mini-title">
                10.1 We will provide at least 7 days prior notice, either on the notice tab on our Website or via E-mail, for any planned
                changes or modifications to this Privacy Policy. Any changes will be effective as of the effective date on the notice. If,
                however, we make any changes to signification provisions such as the purposes for collecting and using personal information
                about you and the circumstances on which we share such information with third parties, then we will provide at least 30 days
                prior notice for such signification changes.
              </p>
              <p class="mini-title">
                10.2 If any changes require your prior consent under applicable laws, including, without limitation, the Act on Promotion of
                Information and Communications Network Utilization and Information Protection, Etc. and Personal Information Protection Act,
                then we will request your consent prior to making any such changes.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>11. YOUR DATA PROTECTION RIGHTS UNDER GDPR</h2>
              <p>
                We make the following efforts to comply with the EU GDPR. <br />- GDPR awareness raising activities <br />- Perform data
                protection impact assessment <br />- Ensuring User Rights <br />- Report and Notification of Personal Data breach
              </p>
            </div>
            <div class="content">
              <p class="mini-title">11.1. GDPR awareness raising activities</p>
              <p>
                We are committed to companywide efforts and attention to complying with the GDPR. We identify the impact GDPR will have on
                our organization and are committed to complying with GDPR on a job-by-job basis by:
                <br />- Participating Department: Customer Management, Human Resources, Finance, Marketing, System Development, etc. <br />-
                Surveys of employees on privacy knowledge levels <br />- Official declaration of management's commitment to comply with GDPR
              </p>
              <p>- Encourage participation in GDPR conferences and seminars <br />- Manage check list of departmental performance items</p>
              <p class="mini-title">11.2. Data Protection Impact Assessment</p>
              <p>
                We conduct a data protection impact assessment if the following situations are likely to pose a high risk to the rights and
                freedoms of natural persons as provided by GDPR.
                <br />- Rating or grading <br />- Automated decision making with legal or similarly important effects<br />- Monitoring
                using the system<br />- Sensitive Information
              </p>
              <p class="mini-title">11.3. User Rights Guarantee</p>
              <p>
                We are committed to ensuring the following user rights as defined by GDPR.<br />- RIGHT TO DELETE (RIGHT TO BE FORGOTTEN)<br />-
                RIGHT TO MOVE PERSONAL INFORMATION<br /><br />11.3.1 Right to Delete (Right to Be Forgotten)<br />You have the right (delete
                right) to request the deletion of personal data related to him.<br />- When personal information is no longer needed for the
                purpose of collection or processing<br />
                - When you withdraw consent for the processing of personal information and there is no legal basis for processing the
                personal information
                <br />
                - When you oppose the processing of personal information pursuant to Article 21 (Right to object), Paragraph 1 of GDPR, and
                there is no justifiable reason to override the processing of related personal data, or the data subject opposes to direct
                marketing pursuant to Article 21, Paragraph 2 of GDPR
                <br />- When personal information is illegally processed<br />- When personal information needs to be deleted to comply with
                EU or EU member state legislation<br />
                - When personal information has been collected in connection with the provision of information and social services to
                children
                <br /><br />However, we can reject a delete request if one of the following is true:<br />- For the exercise of the right to
                freedom of expression and information<br />
                - To comply with legal obligations of the EU or EU Member States, to perform public affairs, or to exercise public authority
                granted to us
                <br />- For public health purposes<br />- For public record keeping and scientific or historical research and statistical
                purposes<br />- For the purpose of demonstrating, exercising or defending a legal claim<br /><br />11.3.2 Right to move
                personal information<br />
                In accordance with Article 20 of the GDPR, Company will provide the personal information collected from you in a
                “systematically structured, generally widely used and machine-readable format.”. You may directly receive the personal
                information from the Company. Alternatively, if technically feasible, you may apply to provide it directly to another
                personal information processor.
              </p>
              <p class="mini-title">11.4. Report and Notification of Personal Data Breach</p>
              <p>
                We will report to the supervisory authority within 72 hours of becoming aware of a breach if any of the following
                infringements occur that could pose a risk to an individual's rights and freedoms:
                <br />- Discrimination<br />- Reputation damage<br />- Financial loss<br />- Secret leakage<br />- Other significant
                economic and social disadvantage risks<br /><br />When there is a high risk of the freedom and rights of the data subject,
                the data subject is notified without delay.
              </p>
            </div>
          </article>
          <article>
            <div class="sub-title">
              <h2>12. YOUR DATA PROTECTION RIGHTS UNDER CPRA</h2>
              <p>
                If you are a California of USA resident, this Section shall apply only to the extent and when OwnXR is regulated under the
                California Privacy Rights Act (the “CPRA”), as a business (as defined in the CPRA). [note: OwnXR may not be eligible as a
                business under CPRA until it satisfies some thresholds.]
              </p>
            </div>
            <div class="content">
              <p class="mini-title">12.1 Consumer Information Collected</p>
              <p>
                We may collect information that identifies, relates to, describes, references, is capable of being associated with, or could
                reasonably be linked, directly or indirectly, with particular California residents, devices or households ("consumer
                information").
              </p>
              <p class="mini-title">12.2 Use of Consumer Information</p>
              <p>
                We may use consumer information for the business or commercial purposes and in the manner described in this Privacy Policy
                with respect to Personal Information.
              </p>
              <p class="mini-title">12.3 Disclosures of Consumer Information for a Business Purpose</p>
              <p>
                We may disclose your consumer information described above to a third party for a business purpose, as described in this
                Privacy Policy with respect to Personal Information of the following categories of consumer information under CPRA:
                <br />- identifiers.<br />- personal information categories listed in the California Customer Records statute (Cal. Civ.
                Code § 1798.80(e)).<br />- internet or other similar network activity.
              </p>
              <p class="mini-title">12.4 Sales of Consumer Information</p>
              <p>
                In the preceding twelve (12) months, OwnXR has not sold, and OwnXR does not and will not sell, consumer information that is
                subject to this Privacy Policy.
              </p>
              <p class="mini-title">12.5 California Residents’ Rights and Choices</p>
              <p>
                The CPRA provides California residents with specific rights regarding their consumer information. This Section 12.5
                describes your CPRA rights (to the extent applicable to you) and explains how to exercise those rights.
                <br />12.5.1 Access to Specific Information and Data Portability Rights<br />
                You may have the right to request that OwnXR disclose certain information to you about our collection and use of your
                consumer information over the past 12 months. Once we receive and confirm your verifiable consumer request (in the manner
                described in Section 12.6 below), to the extent required by the CPRA, we will disclose to you.
                <br />12.5.2 Deletion, Correction Request Rights<br />
                You have the right to request that OwnXR delete or correct any of your consumer information that we collected from you and
                retained, subject to certain exceptions. Once we receive and confirm a verifiable request from you (if you are a California
                resident) in the manner described in Section 12.6 below (“verifiable consumer request”), we will delete or correct (and
                direct our service providers to delete) your consumer information from our records, unless an exception applies. We may deny
                your deletion request if retaining the information is necessary for our or our service provider(s)’ legitimate business
                purposes.
              </p>
              <p class="mini-title">12.6 Exercising Access, Data Portability, and Deletion Rights</p>
              <p>
                12.6.1 Exercise of Rights To exercise the access, data portability, and deletion rights described in Section 12.5 above,
                please submit a verifiable consumer request to us by either: (1) calling us at the number notified in this Privacy Policy
                (2) visiting our website; or (3) contacting us. Only you, or a person registered with the California Secretary of State that
                you authorize to act on your behalf, may make a verifiable consumer request related to your consumer information. 12.6.2
                Company’s Response to Consumer Request We endeavor to respond to a verifiable consumer request within forty-five (45) days
                of its receipt. If we require more time (up to ninety (90) days), we will inform you of the reason and extension period in
                writing.
              </p>
              <p class="mini-title">12.7 Non-Discrimination</p>
              <p>
                We will not discriminate against you for exercising any of your CPRA rights, including, unless permitted by the CPRA, by:
                <br />- denying you goods or services.<br />
                - charging you different prices or rates for goods or services, including through granting discounts or other benefits, or
                imposing penalties.
                <br />- providing you a different level or quality of goods or services.<br />
                - suggesting that you may receive a different price or rate for goods or services or a different level or quality of goods
                or services.
              </p>
            </div>
          </article>
          <br />
          <p>This Privacy Policy is effective as of October 13th, 2023.</p>
        </div>
      </div>
    }
  </section>
  <section class="footer" [class.none]="isScrolling">
    <div class="legal-info">
      <div class="terms" (click)="openDetails('terms-of-use')">{{ 'accountPanel.footer.terms' | translate }}</div>
      <div class="privacy-policy" (click)="openDetails('privacy-policy')">{{ 'accountPanel.footer.privacyPolicy' | translate }}</div>
    </div>
  </section>
</div>
