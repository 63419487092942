import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { LanguageCode } from '@/data/src/lib/services/account.service';
import { ReleaseLog, ReleaseLogService } from '@/data/src/lib/services/release-log.service';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { AccountOption } from '@/data/src/lib/models/data/account';

@Component({
  selector: 'm-account-panel',
  templateUrl: './m-account-panel.component.html',
  styleUrls: ['./m-account-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAccountPanelComponent implements OnInit {
  account$ = this._accountPanelService.account$;
  currentLang$ = this._accountPanelService.currentLang$;
  latestReleaseVersion$ = this._releaseLogService.releaseLog$.pipe(map((log: ReleaseLog[]) => log[0].VersionNumber));
  unviewedNotificationsNumber$ = this._accountPanelService.notifications$.pipe(
    map((items) => items.filter((item) => item.HasBeenViewed === false).length),
  );
  email$ = this._accountPanelService.email$;
  openedDetails$ = this._accountPanelService.openedDetails$;

  languageCode = LanguageCode;
  accountOptions: AccountOption[] = [];
  currentAccount: AccountOption | null;
  fileUploading = false;

  get isEnterprise() {
    return this.accountOptions.length > 1;
  }

  constructor(
    private _accountPanelService: AccountPanelService,
    private _releaseLogService: ReleaseLogService,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  onLogout() {
    this._accountPanelService.logout();
  }

  openDetails(name: string): void {
    this._accountPanelService.openDetails(name);
  }

  onClose() {
    this._accountPanelService.closePanel();
  }

  async uploadFile(event) {
    this.fileUploading = true;
    this._cd.detectChanges();
    await this._accountPanelService.uploadFile(event);
    this.fileUploading = false;
    this._cd.detectChanges();
  }
}
