import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AccountService, LanguageCode } from '@/data/src/lib/services/account.service';
import { Account } from '@/data/src/lib/models/data/account';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountPanelService } from '@/ui/src/lib/layout/account-panel/account-panel.service';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'm-header',
  templateUrl: './m-header.component.html',
  styleUrls: ['./m-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderComponent implements OnInit {
  @Input() headerType: 'landing' | null = 'landing';

  isPanelOpened$ = this._accountPanelService.isPanelOpened$;
  account?: Account;
  currentLanguage$ = this._accountService.language$;
  languageOptions = [
    { name: 'ko', displayName: 'ko' },
    { name: 'en', displayName: 'en' },
  ];

  public urlChanged = new Subject<{ url: string; state: any }>();

  constructor(
    private _accountPanelService: AccountPanelService,
    private _accountService: AccountService,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    private location: Location,
  ) {
    // First time loading, check the current url to render Header
    if (this._router.url === '/') {
      this.headerType = 'landing';
    } else {
      this.headerType = null;
    }

    // Listen on URL changes and push changes to Observable
    this.location.onUrlChange((url, state) => {
      this.urlChanged.next({ url, state });
    });
  }

  async ngOnInit() {
    await this._accountService.activeAccount$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.account = res;
      this._cd.detectChanges();
    });

    // Subscribe urlChanged Observable to watch for URL changes
    this.urlChanged.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.url === '/') {
        this.headerType = 'landing';
      } else {
        this.headerType = null;
      }
      this._cd.detectChanges();
    });
  }

  loginRedirect() {
    this._accountService.loginRedirect();
  }

  openPanel(event) {
    event.stopPropagation();
    event.preventDefault();
    this._accountPanelService.openPanel();
  }

  onLanguageSelect(language: LanguageCode) {
    this._accountPanelService.saveLanguage(language);
  }
}
