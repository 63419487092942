import { Configurations } from '@/data/src/lib/enums/configurations';

export const environment = {
  configuration: Configurations.Staging,
  production: false,
  us: {
    crudApiUrl: 'https://ownxr-staging-ko.azurewebsites.net/api',
    functionApiUrl: 'https://ownxr-file-access-new.azurewebsites.net/api',
    fileStorageUrl: 'https://uroomstorage-cdn.azureedge.net',
    messengerUrl: 'https://api-staging.ownxr.com/ChatHub',
  },
  kr: {
    crudApiUrl: 'https://ownxr-staging-ko.azurewebsites.net/api',
    functionApiUrl: 'https://ownxr-file-access-new.azurewebsites.net/api',
    fileStorageUrl: 'https://uroomstorage-cdn.azureedge.net',
    messengerUrl: 'https://api-staging.ownxr.com/ChatHub',
  },
  functionApi: 'https://global-function-api.azurewebsites.net/api',
  redirectURL: 'https://m.staging.ownxr.com',
  viewerURL: 'https://m.staging.ownxr.com/view',
  version: require('../../../../package.json').version,
  oxrEnterpriseId: 'd85ceaaf-f123-4c4a-a0b4-65ee6aa7303c',
  /** msal-angular config : msalConfig, apiConfig, b2cPolicies */
  msalConfig: {
    auth: {
      clientId: 'fd48c316-b774-4e3f-bd18-b55871c29fc3',
    },
  },
  apiConfig: {
    scopes: ['https://uroomauth.onmicrosoft.com/fd48c316-b774-4e3f-bd18-b55871c29fc3/Read'],
    uri: ['https://ownxr-staging-ko.azurewebsites.net/api', 'https://ownxr-file-access-new.azurewebsites.net/api'],
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1_SignUpSignIn',
      resetPassword: 'B2C_1_Reset',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://uroomauth.b2clogin.com/uroomauth.onmicrosoft.com/B2C_1_SignUpSignIn',
      },
      resetPassword: {
        authority: 'https://uroomauth.b2clogin.com/uroomauth.onmicrosoft.com/b2c_1_reset',
      },
    },
    authorityDomain: 'uroomauth.b2clogin.com',
  },
};
